import store from '@/store/index.js'
export const setChannel = (vm) => {
  let params = vm.$route.params
  let { channel, subchannel } = params
  if (channel) {
    store.commit('setChannel', channel)
  }else {
    store.commit('setChannel', '')
  }
  if (subchannel) {
    store.commit('setSubChannel', subchannel)
  }else {
    store.commit('setSubChannel', '')
  }
}
