import request from '@/api/axios.js'
// 收集埋点
export function collectPointApi(obj) {
  return request({
    url: '/protal/zxwlBuryPoint/collectPoint',
    method: 'post',
    data: obj
  })
}
export function getH5TokenApi(query) {
  return request({
    url: '/protal/phoneAuth/getH5Token',
    method: 'get',
    params: query
  })
}
export function sendCodeApi(query) {
  return request({
    url: '/protal/phoneAuth/sendCode',
    method: 'get',
    params: query
  })
}
// H5号码认证
export function verifyPhoneApi(query) {
  return request({
    url: '/protal/phoneAuth/verifyPhoneWithTokenRequest',
    method: 'get',
    params: query
  })
}
// 校验验证码
export function verificationCodeApi(query) {
  return request({
    url: '/protal/phoneAuth/verificationCode',
    method: 'get',
    params: query
  })
}
// 提交表单
export function submitFormApi(obj) {
  return request({
    url: '/protal/customerForm/submit',
    method: 'post',
    data: obj
  })
}

// 根据子渠道code查询活动id
export function getActivityByCodeApi(query) {
  return request({
    url: '/admin/subChannel/getActivityByCode',
    method: 'get',
    params: query
  })
}

// 获取微信公众号签名
export function getSignatureApi(query) {
  return request({
    url: '/protal/weixinAuth/getSignature',
    method: 'get',
    params: query
  })
}
// 查询所有已有推荐陵园的城市信息
export function getRecommendCitiesApi(query) {
  return request({
    url: '/protal/cemetery/getRecommendCities',
    method: 'get',
    params: query
  })
}
// 获取当前城市code
export function getCityCodeApi(query) {
  return request({
    url: '/protal/cemetery/getCityCode',
    method: 'get',
    params: query
  })
}
// 分页查询所在城市推荐墓地
export function getPageListApi(query) {
  return request({
    url: '/protal/cemetery/cemeteryPage',
    method: 'get',
    params: query
  })
}
// 获取陵园详情
export function getCDetailApi(query) {
  return request({
    url: '/admin/cemetery/detail',
    method: 'get',
    params: query
  })
}
// 分页查询所在城市推荐墓地
export function getPageListNewApi(query) {
  return request({
    url: '/protal/cemetery/page',
    method: 'get',
    params: query
  })
}
// 分页查询咨询列表
export function getSeeListApi(query) {
  return request({
    url: '/protal/seekAdviceFrom/page',
    method: 'get',
    params: query
  })
}

// 根据ID查询咨询详情
export function getSeeDetalApi(query) {
  return request({
    url: '/protal/seekAdviceFrom/getSeekAdviceFromById',
    method: 'get',
    params: query
  })
}

// 根据ID查询咨询详情
export function seekAdviceApi(query) {
  return request({
    url: '/protal/seekAdviceFrom/scanSeekAdviceFrom',
    method: 'get',
    params: query
  })
}














