var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('van-field',{staticClass:"form-item",attrs:{"name":"name","label":"您的姓名","label-width":"1.2rem","label-align":"right","colon":"","placeholder":"请输入您的姓名","rules":[{ required: true, message: '请填写您的姓名' }]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('van-field',{staticClass:"form-item",attrs:{"name":"phone","label":"手机号码","label-align":"right","label-width":"1.2rem","maxlength":"11","colon":"","type":"tel","placeholder":"请输入您的手机号","rules":[
        { required: true, message: '请输入您的手机号' },
        {
          validator: _vm.validatorPhone,
          message: '手机号格式不正确',
          trigger: 'onBlur',
        },
      ]},on:{"input":_vm.changePhone},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}}),(_vm.showCode && _vm.needCode)?_c('van-field',{staticClass:"form-item",attrs:{"center":"","colon":"","label":"验证码","name":"code","label-align":"right","label-width":"1.2rem","placeholder":"请输入短信验证码","rules":[{ required: true, message: '请输入短信验证码' }]},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('van-button',{staticClass:"sendBtn",attrs:{"native-type":"button","disabled":_vm.sendDisabled,"size":"small","type":"primary"},on:{"click":_vm.sendCodeFun}},[_vm._v(_vm._s(_vm.sendText))])]},proxy:true}],null,false,954763541),model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}}):_vm._e(),_c('van-button',{staticClass:"submit-btn",attrs:{"native-type":"submit"}},[_vm._v("立即预约看墓")])],1),_c('div',{staticClass:"agreement-item"},[_c('p',[_vm._v(" 点击预约参观表示您已同意"),_c('span',{on:{"click":_vm.showAgreement}},[_vm._v("《用户隐私协议》")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.shoLoading),expression:"shoLoading"}],staticClass:"loading-g"},[_c('div',{staticClass:"loading-wrap"},[_c('van-loading',{attrs:{"size":"24px","color":"#fff","vertical":""}},[_vm._v("加载中...")])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-title"},[_c('h3',{staticClass:"title"},[_vm._v("预约"),_c('span',[_vm._v("免费接送")]),_vm._v("参观")]),_c('div',{staticClass:"last-title"},[_c('img',{staticClass:"ac-icon",attrs:{"src":"https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/ac-icon_408990549295710245.png","alt":""}}),_c('p',[_vm._v("现在预约即送2000元购墓优惠")]),_c('img',{staticClass:"co-icon",attrs:{"src":"https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/co-icon_409000805849579555.png","alt":""}})])])
}]

export { render, staticRenderFns }