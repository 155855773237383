<template>
  <div id="app">
    <keep-alive>
      <router-view v-wechat-title="$route.meta.title" />
    </keep-alive>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
import { guid } from "@/util/util.js";
export default {
  data() {
    return {};
  },
  mounted() {},
  created() {
    if (!this.UUID) {
      // this.$Device.Info().then((data) => {
      //   let uuid = guid()
      //   this.setUUID(data.UUID);
      // });
      let uuid = guid();
      this.setUUID(uuid);
    }
  },
  computed: {
    ...mapGetters(["UUID"]),
  },
  methods: {
    ...mapMutations(["setUUID"]),
  },
};
</script>

<style lang="scss">
	@import "@/assets/styles/scss/font-face.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.box-wrap {
  width: 7.5rem;
  min-height: 100vh;
  margin: 0 auto;
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
}
.van-dialog__header {
  font-size: .36rem;
}
.van-dialog__message {
  font-size: .30rem;
}
.van-dialog__confirm, .van-dialog__cancel {
  font-size: .34rem;
}
.beian {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #c1c1c1;
  line-height: 0.36rem;
}
</style>
