import router from '../router'
import { Dialog } from 'vant';
export const goPath = function (link, linkType = 1, type = 'push') {
  return new Promise((resolve, reject) => {
    if (linkType === 1) {
      if (type === 'push') {
        router.push(link)
      }else if(type == 'to') {
        router.replace(link)
      }
    } else if (linkType === 2) {
      Dialog.confirm({
        title: '温馨提示',
        message: '确定要前往外部网站吗? ',
      })
        .then(() => {
          window.location.href = link;
        })
        .catch(() => {

        });
    } else {
      let str = linkType + '跳转类型未定义'
      reject(str)
    }
    resolve()
  })

}