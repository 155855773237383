var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-wrap"},[_c('div',{staticClass:"qBox"},[_c('div',{staticClass:"qItem",class:{
        activeItem: _vm.curent === 0,
        hideItem: _vm.isHideIndex === 0,
        showItem: _vm.isHideIndex === -1,
      }},[_vm._m(0),_c('div',{staticClass:"qContentCss"},[_c('div',{staticClass:"qContent"},[_vm._l((_vm.questionOne.list),function(item,index){return [_c('QuestionChoose',{key:index,attrs:{"questionData":item,"questionKey":"question1","questionIndex":index,"type":"two"},on:{"emitQuestionClick":_vm.emitQuestionClick},scopedSlots:_vm._u([{key:"title",fn:function(){return [(index === 0)?_c('div',{staticClass:"questionTitle pd64"},[_c('div',{staticClass:"titleIndex"},[_c('h3',[_vm._v("1")]),_c('h4',[_vm._v("/4")])]),_c('p',[_vm._v("请问您是给家里哪位亲人看墓？")])]):_c('div',{staticClass:"centerTitle"},[_vm._v(" 是否需要"),_c('span',[_vm._v("殡仪服务")])])]},proxy:true}],null,true)})]})],2)]),_c('div',{staticClass:"nextBtn",on:{"click":_vm.nextClick}},[_vm._v("下一题")])]),_c('div',{staticClass:"qItem",class:{
        activeItem: _vm.curent === 1,
        showItem: _vm.isHideIndex === 0,
        hideItem: _vm.isHideIndex === 1,
        leftItem: _vm.curent === 10,
      }},[_vm._m(1),_c('div',{staticClass:"qContentCss"},[_c('div',{staticClass:"qContent"},[_vm._l((_vm.questionTwo.list),function(item,index){return [_c('QuestionChoose',{key:index,attrs:{"questionData":item,"questionKey":"question2","questionIndex":index,"type":"one"},on:{"emitQuestionClick":_vm.emitQuestionClick},scopedSlots:_vm._u([{key:"title",fn:function(){return [(index === 0)?_c('div',{staticClass:"questionTitle pd64"},[_c('div',{staticClass:"titleIndex"},[_c('h3',[_vm._v("2")]),_c('h4',[_vm._v("/4")])]),_c('p',[_vm._v(" 九公山墓型多样，有标准墓、艺术墓、家族自选墓，价位由1.9万至80万不等；请问您本次购墓预算大概在多少？ ")])]):_vm._e()]},proxy:true}],null,true)})]})],2)])]),_c('div',{staticClass:"qItem",class:{
        activeItem: _vm.curent === 2,
        showItem: _vm.isHideIndex === 1,
        hideItem: _vm.isHideIndex === 2,
        leftItem: _vm.curent === 20,
      }},[_vm._m(2),_c('div',{staticClass:"qContentCss"},[_c('div',{staticClass:"qContent"},[_vm._l((_vm.questionThree.list),function(item,index){return [_c('QuestionChoose',{key:index,attrs:{"questionData":item,"questionKey":"question3","questionIndex":index},on:{"emitQuestionClick":_vm.emitQuestionClick},scopedSlots:_vm._u([{key:"title",fn:function(){return [(index === 0)?_c('div',[_c('div',{staticClass:"questionTitle"},[_c('div',{staticClass:"titleIndex"},[_c('h3',[_vm._v("3")]),_c('h4',[_vm._v("/4")])]),_c('p',[_vm._v("请问您的年龄和性别分别是？")])]),_c('div',{staticClass:"lastTitle"},[_vm._v("选择您的年龄")])]):_c('div',{staticClass:"lastTitle"},[_vm._v("选择您的性别")])]},proxy:true}],null,true)})]})],2)]),_c('div',{staticClass:"nextBtn",on:{"click":_vm.nextClick}},[_vm._v("下一题")])]),_c('div',{staticClass:"qItem",class:{
        activeItem: _vm.curent === 3,
        showItem: _vm.isHideIndex === 2,
        hideItem: _vm.isHideIndex === 3,
        leftItem: _vm.curent === 30,
      }},[_vm._m(3),_c('div',{staticClass:"qContentCss"},[_c('div',{staticClass:"qContent"},[_vm._l((_vm.questionFour.list),function(item,index){return [_c('QuestionChoose',{key:index,attrs:{"questionData":item,"questionKey":"question4","questionIndex":index,"type":"one"},on:{"emitQuestionClick":_vm.emitQuestionClick},scopedSlots:_vm._u([{key:"title",fn:function(){return [(index === 0)?_c('div',{staticClass:"questionTitle pd64"},[_c('div',{staticClass:"titleIndex"},[_c('h3',[_vm._v("4")]),_c('h4',[_vm._v("/4")])]),_c('p',[_vm._v("您什么时间段方便接听电话？")])]):_vm._e()]},proxy:true}],null,true)})]})],2)])]),(_vm.isHideIndex > -1 && _vm.isHideIndex !== _vm.curent)?_c('div',{staticClass:"fiexdTip"},[(_vm.isHideIndex !== _vm.curent)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.fixedText[_vm.curent])}}):_vm._e()]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qHeader oneItem"},[_c('img',{staticClass:"icon",attrs:{"src":"https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/success-icon_409031363782721576.png","alt":""}}),_c('h3',[_vm._v("预约信息已提交")]),_c('p',[_vm._v("花15秒完成下面预约资料，")]),_c('p',[_vm._v("我们为您更好的安排免费参观服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qHeader twoItem"},[_c('img',{staticClass:"hedaer-icon",attrs:{"src":"https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/header_409031363845636118.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qHeader twoItem"},[_c('img',{staticClass:"hedaer-icon",attrs:{"src":"https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/header_409031363845636118.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qHeader twoItem"},[_c('img',{staticClass:"hedaer-icon",attrs:{"src":"https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/header_409031363845636118.png","alt":""}})])
}]

export { render, staticRenderFns }