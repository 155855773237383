// import { serialize } from '@/util/store'
import { getStore, serialize } from '@/util/store.js'
// import NProgress from 'nprogress' // progress bar
import { Toast } from 'vant';
import 'nprogress/nprogress.css'
import qs from 'qs'
import axios from 'axios'
const errorCode = {
  '000': '操作太频繁，请勿重复请求',
  '401': '当前操作没有权限',
  '403': '当前操作没有权限',
  '404': '资源不存在',
  '417': '未绑定登录账号，请使用密码登录后绑定',
  '423': '演示环境不能操作，如需了解联系管理员',
  '426': '用户名不存在或密码错误',
  '428': '验证码错误,请重新输入',
  '429': '请求过频繁',
  '479': '演示环境，没有权限操作',
  'default': '系统未知错误,请反馈给管理员'
}
const urlArr = [
  '/protal/phoneAuth/getH5Token', // 获取H5token
  '/protal/phoneAuth/sendCode', // 获取验证码
  '/protal/phoneAuth/verifyPhoneWithTokenRequest' // H5号码认证
]
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
let url = window.location.href
if(url.includes('https://test-h5.zhxweilai.com/') || url.includes('https://test-h5.rushanxm.com/')) {
  axios.defaults.baseURL = 'https://test-api.zhxweilai.com'
}
axios.defaults.timeout = 1200000
// 返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500 // 默认的
}
// 跨域请求，允许保存cookie
// axios.defaults.withCredentials = true
// // NProgress Configuration
// NProgress.configure({
//   showSpinner: false
// })

// HTTPrequest拦截
axios.interceptors.request.use(config => {
  if (config.url !== '/pms/antifakecode/process') {
    // NProgress.start() // 进度条
  }
  // const tenantObject = getStore({ name: 'tenantId' });
  const isToken = (config.headers || {}).isToken === false
  // const token = store.getters.access_token
  const uuid = getStore({
    name: '_UUID'
  }) || ''

  if (uuid && !isToken ) {
    // if(config.data) {
    //   config.data.uuid = uuid
    // }

    // config.headers['uuid'] =  uuid// uuid
  }
  // headers中配置serialize为true开启序列化
  if (config.method === 'post' && config.headers.serialize) {
    config.data = serialize(config.data)
    delete config.data.serialize
  }

  if (config.method === 'get') {
    config.paramsSerializer = function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  }

  return config
}, error => {
  return Promise.reject(error)
})

let statusFlag = 0;
// HTTPresponse拦截
axios.interceptors.response.use(res => {
  // NProgress.done()
  const status = Number(res.status) || 200
  const code = Number(res.data.code) || 0
  const message = res.data.message || errorCode[status] || errorCode['default']
  if (status === 401 || code === 401) {
    console.log('执行!!!');
    if (statusFlag === 0) {
      Toast({
        message: message,
        type: 'fail'
      })
      statusFlag = 401;
    }
    return
  }
  if (statusFlag === 401) statusFlag = 0;
  if (status !== 200 || res.data.code === 1 || res.data?.code != '200') {
    console.log(res,'请求异常了');
    if(urlArr.includes(res.config.url)) {
      if(res.data?.body?.code === 'OK') {
        return Promise.resolve(res.data.body)
      }else {
        return Promise.resolve(res)
      }
    }
    if(res.config.url == '/protal/phoneAuth/verifyPhoneWithTokenRequest') {
      return Promise.reject({message: '接口失败,兜底'})
    }
    Toast({
      message: message,
      type: 'fail'
    })
    return Promise.reject(message)
  }

  return res
}, error => {
  // NProgress.done()
  return Promise.reject(new Error(error))
})

export default axios
