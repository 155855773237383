
import { setStroe, getStore } from '../../util/store'
const side = {
  state: {
    userInfo: getStore({
      name: '_userInfo'
    }),
    UUID: getStore({
      name: '_UUID'
    }),
  },
  mutations: {
    setUserInfo(state, info) {
      state.userInfo = info
      setStroe({
        name: "_userInfo",
        content: info,
      })
    },
    setUUID(state, id) {
      state.UUID = id
      setStroe({
        name: "_UUID",
        content: id,
      })
    }
  },
  actions: {
    newUserInfo({ commit }, info) {
      return new Promise((resolve, reject) => {
        if (info) {
          commit('setUserInfo', info)
          resolve(info)
        } else {
          reject('设置失败')
        }
      })
    },
    clearUserInfo({ commit }, info) {
      return new Promise((resolve, reject) => {
        if (!info) {
          commit('setUserInfo', info)
          resolve(info)
        } else {
          reject('设置失败')
        }
      })
    },
  },
}
export default side