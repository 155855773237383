import { setStroe, getStore } from '../../util/store'
const state = {
  state: {
    search: window.sessionStorage.getItem('searchVlaue') || '',
    cityListX: window.sessionStorage.getItem('cityListX') ? JSON.parse(window.sessionStorage.getItem('cityListX')) : [],
    isTip: getStore({
      name: '_isTip'
    }),
    cityName: ""
  },
  mutations: {
    setSearch(state, value) {
      window.sessionStorage.setItem('searchVlaue', value)
      state.search = value
    },
    setCityListX(state, list) {
      window.sessionStorage.setItem('cityListX', JSON.stringify(list))
      state.cityListX = list
    },
    setIsTip(state, flag) {
      state.isTip = flag
      setStroe({
        name: "_isTip",
        content: flag,
      })
    },
    setCityName(state, cityName) {
      state.cityName = cityName
    }
  },
}
export default state