<template>
  <div class="box-css">
    <div class="title">
      <slot name="title" />
    </div>
    <div class="list">
      <div
        class="questionItem"
        :class="{ avtive: activeIndex === index, oneType: type === 'one' }"
        v-for="(item, index) of questionData.list"
        :key="index"
        @click="questionClick(item, index)"
      >
        {{ item.key }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "questionChoose",
  props: {
    questionData: {
      type: Object,
      default: function () {
        return {
          index: "",
          list: [],
        };
      },
    },
    questionKey: {
      type: String,
      default: "",
    },
    questionIndex: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: "two",
    },
  },
  created() {
    // console.log(this.questionData, "questionData");
  },
  data() {
    return {
      activeIndex: -1,
    };
  },
  methods: {
    questionClick(item, index) {
      this.activeIndex = index;
      this.$emit("emitQuestionClick", {
        questionKey: this.questionKey,
        questionIndex: this.questionIndex,
        data: item,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.box-css {
  .list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .questionItem {
      width: 2.78rem;
      height: 0.84rem;
      margin-bottom: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 45px;
      border: 1px solid #703913;
      font-size: 0.32rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6c340d;
    }
    .avtive.questionItem {
      background: linear-gradient(
        180deg,
        rgba(246, 217, 193, 0.6) 0%,
        rgba(211, 167, 126, 0.6) 100%
      );
      color: #6C340D;
    }
    .oneType.questionItem {
      width: 100%;
    }
  }
}
</style>