import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import { goPath } from "@/util/goPath.js"
import { Loading } from 'vant';
import { Toast } from 'vant';
import { Button } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import '@/assets/styles/border.css'
import '@/assets/styles/reset.css'
import { Lazyload } from 'vant';
import VueWechatTitle from 'vue-wechat-title'
import { NoticeBar } from 'vant';
import { Image as VanImage } from 'vant';
import { Uploader } from 'vant';
import { Dialog } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Icon } from 'vant';
import { Popup } from 'vant';
import { Empty } from 'vant';
import { ActionSheet } from 'vant';
import { Overlay } from 'vant';
import { Search } from 'vant';
import { DropdownMenu, DropdownItem } from 'vant';
import { Tab, Tabs } from 'vant';
import { Divider } from 'vant';

import Device from '@skillnull/device-js'
import { PhoneNumberServer } from 'aliyun_numberauthsdk_web'; 
import {_deepCopyFn} from '@/util/util.js'
import wxJs from '@/util/wx.js'
if (window.__wxjs_is_wkwebview) {
  // IOS
  if (window.entryUrl == "" || window.entryUrl == undefined) {
    var url = location.href.split("#")[0];
    window.entryUrl = url; // 将后面的参数去除
  }
  wxJs.wxRegister(location.href.split("#")[0], "ios");
} else {
  setTimeout(function () {
    wxJs.wxRegister(location.href.split("#")[0], "android");
  }, 500);
}
// import VConsole from 'vconsole';
// let url = window.location.href
// if(!url.includes('www.zhxweilai.com')) {
//   const vConsole = new VConsole();
//   console.log(vConsole,'vConsole');
// }
Vue.use(Overlay);
Vue.use(ActionSheet);
Vue.use(Popup);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Field);
Vue.use(Dialog);
Vue.use(Uploader);
Vue.use(VanImage);
Vue.use(NoticeBar);
Vue.use(VueWechatTitle)
Vue.use(Lazyload);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Button);
Vue.use(Toast);
Vue.use(Loading);
Vue.use(VueAxios, axios)
Vue.use(VueCookies)
Vue.use(Search);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Divider);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Empty);

var phoneNumberServer = new PhoneNumberServer();
Vue.prototype.$goPath = goPath // 定义公共跳转方法
Vue.prototype.$toast = Toast // 全局定义Toast
Vue.prototype.$Device = Device // 全局定义Toast
Vue.prototype.$phoneNumberServer = phoneNumberServer // 阿里云号码认证服务
Vue.prototype.$deepCopyFn = _deepCopyFn // 深克隆
phoneNumberServer.setLoggerEnable(true);

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
