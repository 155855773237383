
import { setStroe } from '../../util/store'
const side = {
  state: {
    activePath: [],
  },
  mutations: {
    setActivePath(state, path) {
      state.activePath = path
      setStroe({
        name: "_path",
        content: path,
      })
    },

  },
  actions: {
    newActivePath({ commit }, path) {
      return new Promise((resolve, reject) => {
        if (path) {
          commit('setActivePath', path)
          resolve(path)
        } else {
          reject('设置失败')
        }
      })
    },
  },
}
export default side