<template>
  <div>
    <div class="form-title">
      <h3 class="title">预约<span>免费接送</span>参观</h3>
      <div class="last-title">
        <img
          class="ac-icon"
          src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/ac-icon_408990549295710245.png"
          alt=""
        />
        <p>现在预约即送2000元购墓优惠</p>
        <img
          class="co-icon"
          src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/co-icon_409000805849579555.png"
          alt=""
        />
      </div>
    </div>
    <van-form @submit="onSubmit">
      <van-field
        class="form-item"
        v-model="formData.name"
        name="name"
        label="您的姓名"
        label-width="1.2rem"
        label-align="right"
        colon
        placeholder="请输入您的姓名"
        :rules="[{ required: true, message: '请填写您的姓名' }]"
      />
      <van-field
        class="form-item"
        v-model="formData.phone"
        name="phone"
        label="手机号码"
        label-align="right"
        label-width="1.2rem"
        maxlength="11"
        colon
        type="tel"
        placeholder="请输入您的手机号"
        @input="changePhone"
        :rules="[
          { required: true, message: '请输入您的手机号' },
          {
            validator: validatorPhone,
            message: '手机号格式不正确',
            trigger: 'onBlur',
          },
        ]"
      />
      <van-field
        class="form-item"
        v-model="formData.code"
        center
        colon
        label="验证码"
        name="code"
        label-align="right"
        label-width="1.2rem"
        placeholder="请输入短信验证码"
        :rules="[{ required: true, message: '请输入短信验证码' }]"
        v-if="showCode && needCode"
      >
        <template #button>
          <van-button
            class="sendBtn"
            native-type="button"
            :disabled="sendDisabled"
            size="small"
            @click="sendCodeFun"
            type="primary"
            >{{ sendText }}</van-button
          >
        </template>
      </van-field>
      <van-button native-type="submit" class="submit-btn"
        >立即预约看墓</van-button
      >
    </van-form>
    <div class="agreement-item">
      <p>
        点击预约参观表示您已同意<span @click="showAgreement"
          >《用户隐私协议》</span
        >
      </p>
    </div>
    <div class="loading-g" v-show="shoLoading">
      <div class="loading-wrap">
        <van-loading size="24px" color="#fff" vertical>加载中...</van-loading>
      </div>
    </div>
  </div>
</template>
<script>
import {
  sendCodeApi,
  verifyPhoneApi,
  verificationCodeApi,
  submitFormApi,
  collectPointApi,
} from "@/api/apiList.js"; // collectPointApi,
import { certifiedPhone } from "@/util/certifiedPhone.js";
import { mapGetters, mapMutations } from "vuex";
import { guid } from "@/util/util.js";
var pageTimer = {};
export default {
  name: "jgsform",
  props: {
    activityId: {
      type: String,
      default: "",
    },
    startTime: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      rules: {},
      showCode: false,
      sendDisabled: false,
      sendText: "获取验证码",
      needCode: false,
      validatorPhoneFlag: false,
      initSendFlag: true,
      formData: {
        name: "",
        phone: "",
        code: "",
      },
      shoLoading: false,
    };
  },
  created() {
    console.log("更新成功2023年02月07日16:40:24");
    // let uuid = guid();
    // console.log(uuid, "uuid");
    // console.log(this.UUID,'UUID');
  },
  computed: {
    ...mapGetters(["pages", "UUID"]),
  },
  methods: {
    ...mapMutations(["setFillPhone", "setActivityId"]),
    validatorPhone(val) {
      // return /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(val);
      return /^1[23456789]\d{9}$/.test(val);
    },
    async changePhone(val) {
      if (this.validatorPhone(val)) {
        this.shoLoading = true;
        let res = await this.certifiedPhoneFun(val);
        this.shoLoading = false;
        console.log(res, "最终返回结果");
        if (res.flag) {
          this.validatorPhoneFlag = true;
          this.needCode = false;
        } else {
          this.validatorPhoneFlag = false;
          this.needCode = true;
          this.showCode = true;
          if (this.initSendFlag) {
            this.initSendFlag = false;
            this.sendCodeFun();
          }
        }
      } else {
        clearInterval(pageTimer["timer"]);
        this.sendDisabled = false;
        this.sendText = "获取验证码";
        this.showCode = false;
      }
    },
    onSubmit(values) {
      if (!this.validatorPhoneFlag) {
        console.log(values, "valuesvaluesvaluesvalues");
        verificationCodeApi({
          phoneNumber: values.phone,
          code: values.code,
        })
          .then((res) => {
            let data = res.data.data;
            console.log(data, "短信校验结果");
            if (data) {
              this.submitFormFun();
            }
          })
          .catch((err) => {
            console.log(err, "错误了");
          });
      } else {
        this.submitFormFun();
      }
      console.log("submit", values);
    },
    showAgreement() {
      this.$emit("showAgreement");
    },
    sendCodeFun() {
      if (this.validatorPhone(this.formData.phone)) {
        var time = 60;
        sendCodeApi({ phoneNumber: this.formData.phone })
          .then((res) => {
            let code = res.data.code;
            console.log(code, "hdkhk");
            console.log(res, "dhkd");
            if (code == "200") {
              this.$toast({
                message: "短信验证码已发送",
                duration: 3000,
              });
              this.sendDisabled = true;
              pageTimer["timer"] = setInterval(() => {
                if (time > 1) {
                  this.sendText = `${time}秒后重新获取`;
                  time--;
                } else {
                  clearInterval(pageTimer["timer"]);
                  this.sendDisabled = false;
                  this.sendText = "获取验证码";
                }
              }, 1000);
            } else {
              this.$toast({
                message: res.data.message,
                duration: 3000,
              });
            }
          })
          .catch((err) => {
            this.sendDisabled = false;
            console.log(err, "获取短信验证码失败的报错");
          });
      } else {
        this.$toast("请输入正确的手机号");
      }
    },
    certifiedPhoneFun(phone) {
      return new Promise((resolve) => {
        certifiedPhone(this).then((res) => {
          console.log(res, "鉴权返回的结果");
          // 如果鉴权成功
          if (res && res.code == 600000) {
            // spToken
            let prarms = {
              phoneNumber: phone,
              spToken: res.spToken,
            };
            verifyPhoneApi(prarms)
              .then((verifyRes) => {
                console.log(verifyRes, "认证结果");
                let data = verifyRes.data?.data?.body || { code: "fail" };
                if (data.code === "OK") {
                  if (
                    data.gateVerify?.verifyResult === "PASS" ||
                    data.GateVerify?.VerifyResult === "PASS"
                  ) {
                    resolve({
                      flag: true,
                      data,
                    });
                  } else {
                    resolve({
                      flag: false,
                      data,
                    });
                  }
                } else {
                  resolve({
                    flag: false,
                    data,
                  });
                }
              })
              .catch((err) => {
                resolve({
                  flag: false,
                  data: err,
                });
              });
          } else {
            resolve({
              flag: false,
              data: {},
            });
          }
        });
      });
    },
    submitFormFun() {
      let unity = guid();
      let prarms = {
        channelCode: this.pages.channel,
        subChannelCode: this.pages.subChannel,
        activityId: this.activityId,
        name: this.formData.name,
        phone: this.formData.phone,
        localUrl: location.href,
        unity,
      };
      let copyPrarms = JSON.parse(JSON.stringify(prarms));
      copyPrarms.uuid = this.UUID;
      copyPrarms.eventType = 1;
      delete copyPrarms.name;
      if (this.startTime) {
        prarms.scanTime = +new Date() - this.startTime;
      }
      submitFormApi(prarms).then((res) => {

        let result = res.data.data;
        // 记录活动id和手机
        this.setFillPhone(prarms.phone);
        this.setActivityId(prarms.activityId);
        // 提交埋点
        collectPointApi(copyPrarms);
        // 成功
        if (result === 1) {
          this.$router.push(
            `/tfhd/jiugongshan/question/${this.pages.channel}/${this.pages.subChannel}`
          );
        } else if (result === 2) {
          // 重复
          // this.$router.push(
          //   `/tfhd/jiugongshan/question/${this.pages.channel}/${this.pages.subChannel}`
          // );
          this.$router.push(`/tfhd/jiugongshan/success?repeat=1`);
        } else {
          // 失败
          this.$toast("预约失败, 请稍后重试...");
        }
        console.log(res, "提交表单结果");
      });
      console.log(prarms, "prarms");
    },
  },
};
</script>
<style lang="scss" scoped>
.form-title {
  text-align: center;
  .title {
    font-size: 0.36rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 0.5rem;
    span {
      color: #b91b3f;
    }
  }
  .last-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.12rem;
    p {
      font-size: 0.28rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #6c340d;
      line-height: 0.4rem;
    }
    .ac-icon {
      width: 0.4rem;
      height: 0.3rem;
      margin-right: 0.04rem;
    }
    .co-icon {
      margin-left: 0.04rem;
      width: 0.5rem;
      height: 0.3rem;
    }
  }
}
.form-item /deep/ {
  padding: 0.22rem 0.3rem;
  border-radius: 0.08rem;
  border: 0.02rem solid #e5c6a9;
  font-size: 0.28rem;
  margin: 0.12rem 0 0.24rem;
  .van-field__label {
    font-size: 0.28rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 0.4rem;
    margin-right: 0.18rem;
    width: auto;
  }

  .sendBtn {
    margin-right: -0.1rem;
    background: #ff9d20;
    border-color: #ff9d20;
    .van-button__text {
      font-size: 0.26rem;
    }
  }
}
.submit-btn {
  width: 100%;
  height: 0.86rem;
  background: linear-gradient(180deg, #eb3659 0%, #b91b3f 100%);
  border-radius: 0.08rem;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.agreement-item {
  padding-top: 0.12rem;
  p {
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 0.33rem;
    span {
      color: #0379fb;
    }
  }
}
.loading-g {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  // align-items: center;
  justify-content: center;
  z-index: 1000;
  padding-top: 5rem;
  .loading-wrap {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 0.2rem;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>