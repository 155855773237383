import Vue from 'vue'
import VueRouter from 'vue-router'
import JgsHome from '@/views/tfhd/jiugongshan/home/index.vue'
import JgsQuestion from '@/views/tfhd/jiugongshan/question/index.vue'
import JgsSuccess from '@/views/tfhd/jiugongshan/result/success.vue'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/homerec/index.vue'),
    meta: {
      title: '如山选墓'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/home/home.vue'),
    meta: {
      title: '众兴未来'
    }
  },
  {
    path: '/tfhd/jiugongshan/home/:channel?/:subchannel?',
    name: 'JgsHome',
    component: JgsHome,
    meta: {
      title: '预约接送参观北京九公山公墓'
    }
  },
  {
    path: '/tfhd/jiugongshan/question/:channel?/:subchannel?',
    name: 'JgsQuestion',
    component: JgsQuestion,
    meta: {
      title: '预约成功'
    }
  },
  {
    path: '/tfhd/jiugongshan/success',
    name: 'JgsSuccess',
    component: JgsSuccess,
    meta: {
      title: '预约成功'
    }
  },
  {
    path: '/homerec/:channel?/:subchannel?',
    name: 'Homerec',
    component: () => import('@/views/homerec/index.vue'),
    meta: {
      title: '如山选墓'
    }
  },
  {
    path: '/rec/search/:channel?/:subchannel?',
    name: 'searchList',
    component: () => import('@/views/searchList/index.vue'),
    meta: {
      title: '如山选墓'
    }
  },
  {
    path: '/rec/detail/:channel?/:subchannel?',
    name: 'cemeteryDetail',
    component: () => import('@/views/cemeteryDetail/index.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/rec/middle/one/:channel?/:subchannel?',
    name: 'middleOne',
    component: () => import('@/views/middle/one.vue'),
    meta: {
      title: '领取专享福利'
    }
  },
  {
    path: '/rec/middle/two/:channel?/:subchannel?',
    name: 'middleTwo',
    component: () => import('@/views/middle/two.vue'),
    meta: {
      title: '预约专车接送'
    }
  },
  {
    path: '/rec/middle/three/:channel?/:subchannel?',
    name: 'middleThree',
    component: () => import('@/views/middle/three.vue'),
    meta: {
      title: '预约顾问咨询'
    }
  },
  {
    path: '/rec/result',
    name: 'result',
    component: () => import('@/views/recResult/index.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/rec/infolist/:channel?/:subchannel?',
    name: 'infolist',
    component: () => import('@/views/info/list.vue'),
    meta: {
      title: '如山选墓'
    }
  },
  {
    path: '/rec/infodetail/:channel?/:subchannel?',
    name: 'infodetail',
    component: () => import('@/views/info/detail.vue'),
    meta: {
      title: '如山选墓'
    }
  },
  {
    path: '/rec/mxdetail/:channel?/:subchannel?',
    name: 'mxdetail',
    component: () => import('@/views/homerec/modalForm.vue'),
    meta: {
      title: ''
    }
  },
]
/* 路由发生变化修改页面title */

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { y: 0}
  }
  
})
router.afterEach(() => {
})
router.beforeEach((to, from, next) => {
  if(to.query.title) {
    to.meta.title = to.query.title
  }
  next()
})
export default router