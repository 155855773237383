function getuuid() {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}

export const guid = function () {
  return (getuuid() + getuuid() + "_" + getuuid() + "_" + getuuid() + "_" + getuuid() + getuuid() + getuuid());

}
// 数据类型判断
export const _typeOf = (obj) => {
  const toString = Object.prototype.toString
  const map = {
    '[object Date]': 'date',
    '[object Null]': 'null',
    '[object Array]': 'array',
    '[object Number]': 'number',
    '[object Object]': 'object',
    '[object RegExp]': 'regExp',
    '[object String]': 'string',
    '[object Boolean]': 'boolean',
    '[object Function]': 'function',
    '[object Undefined]': 'undefined',
    '[object HTMLCollection]': 'htmlCollection',
    '[object HTMLDivElement]': 'div',
    '[object HTMLSpanElement]': 'span'
  }
  return map[toString.call(obj)]
}

// 深克隆
export const _deepCopyFn = (data) => {
  const t = _typeOf(data)
  let o = null
  if (t === 'array') {
    o = []
  } else if (t === 'object') {
    o = {}
  } else {
    return data
  }
  if (t === 'array') {
    for (let i = 0; i < data.length; i++) {
      o.push(_deepCopyFn(data[i]))
    }
  } else if (t === 'object') {
    for (let i in data) {
      o[i] = _deepCopyFn(data[i])
    }
  }
  return o
}