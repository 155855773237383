
import { setStroe, getStore } from '../../util/store'
const side = {
  state: {
    feedbackList: getStore({
      name: '_feedbackList'
    }) || [],
  },
  mutations: {
    setFeedbackListInfo(state, feedback) {
      state.feedbackList.push(feedback)
      setStroe({
        name: "_feedbackList",
        content: state.feedbackList,
      })
    },

  },
  actions: {
    newFeedbackList({ commit }, info) {
      return new Promise((resolve, reject) => {
        if (info) {
          commit('setFeedbackListInfo', info)
          resolve(info)
        } else {
          reject('设置失败')
        }
      })
    },
  },
}
export default side