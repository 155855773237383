const getters = {
  activePath: state => state.tabbar.activePath,
  userInfo: state => state.user.userInfo,
  feedbackList: state => state.feedback.feedbackList,
  UUID: state => state.user.UUID,
  pages: state => state.pages.pages,
  search: state => state.search.search,
  cityListX: state=> state.search.cityListX,
  cityName : state=> state.search.cityName,
  isTip: state=> state.search.isTip,
  cityCode: state=> state.pages.cityCode,
  scanSeekIds: state=> state.pages.scanSeekIds,
}
export default getters