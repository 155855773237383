// import wx from 'weixin-js-sdk' 
import { getSignatureApi } from '../api/apiList'
const wxApi = {
  /**
  * [wxRegister 微信Api初始化]
  * @param  {Function} callback [ready回调函数]
  */

  wxRegister(url, sys) {
    if (sys == "ios") {
      url = window.entryUrl;
    }
    let wx = window.wx;
    getSignatureApi({ url }).then(res => {
      let data = res.data.data
      let params = {
        debug: false, // 开启调试模式
        appId: 'wx83e04856a9c77dd2', // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.noncestr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见附录1
        jsApiList: [
          'checkJsApi',//必填，检测api是否有权限
          //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
          'updateAppMessageShareData',
          //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
          'updateTimelineShareData',
          'onMenuShareAppMessage',
          'onMenuShareTimeline'
        ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      }
      wx.config(params)
      let updateAppMessageShareData = null
      if (wx.updateAppMessageShareData) {
        updateAppMessageShareData = wx.updateAppMessageShareData
        console.log(wx.updateAppMessageShareData, ' wx.updateAppMessageShareData');
      } else {
        updateAppMessageShareData = wx.onMenuShareAppMessage
      }
      let updateTimelineShareData = null
      if (wx.updateTimelineShareData) {
        updateTimelineShareData = wx.updateTimelineShareData
      } else {
        updateTimelineShareData = wx.onMenuShareTimeline
      }
      wx.ready(function () {
        let links = window.location.href.split('#')[0] + '#' + window.location.href.split('#')[1]
        let optionApp = {
          title: " 预约接送参观北京高端公墓，享内部优惠！", // 分享标题
          desc: "高端生态陵园，免费接送参观~", // 分享描述
          link: links, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl:
            "https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/%E5%88%86%E4%BA%AB%E9%93%BE%E6%8E%A5%E5%9B%BE%E7%89%87_415271171098099726.jpg",
        }// 分享图标
        let optionTimeline = {
          title: " 预约接送参观北京高端公墓，享内部优惠！", // 分享标题
          desc: "高端生态陵园，免费接送参观~", // 分享描述
          link: links, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl:
            "https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/%E5%88%86%E4%BA%AB%E9%93%BE%E6%8E%A5%E5%9B%BE%E7%89%87_415271171098099726.jpg",
        }//
        // 微信分享给朋友
        updateAppMessageShareData({
          title: optionApp.title, // 分享标题
          link: optionApp.link, // 分享链接
          imgUrl: optionApp.imgUrl, // 分享图标
          // imgUrl:'https://www.gov.cn/shouye/datu/202310/W020231012305103544587_ORIGIN.jpg', // 分享图标

          desc: optionApp.desc, // 分享图标
        })
        updateTimelineShareData({
          title: optionTimeline.title, // 分享标题
          link: optionTimeline.link, // 分享链接
          imgUrl: optionTimeline.imgUrl, // 分享图标
          desc: optionTimeline.desc
        })
      });
      wx.error(function (err) {
        console.log('失败', err);
        // alert(JSON.stringify(e)+'--------error')
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      });
    })


  },
}
export default wxApi