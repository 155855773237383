
import { setStroe, getStore } from '../../util/store'
const side = {
  state: {
    channel: getStore({
      name: '_channel'
    }),
    subChannel: getStore({
      name: '_subChannel'
    }),
    fillPhone: getStore({
      name: 'fillPhone'
    }),
    activityId: '',
    cityCode: '',
    pages: {
      channel: getStore({
        name: '_channel'
      }),
      subChannel: getStore({
        name: '_subChannel'
      }),
      fillPhone: getStore({
        name: 'fillPhone'
      }),
      activityId: getStore({
        name: 'activityId'
      }),
    },
    scanSeekIds:  getStore({
      name: 'scanSeekIds'
    }) || []
  },
  mutations: {
    setChannel(state, code) {
      state.channel = code
      state.pages.channel = code
      setStroe({
        name: "_channel",
        content: code,
      })
    },
    setSubChannel(state, code) {
      state.subChannel = code
      state.pages.subChannel = code
      setStroe({
        name: "_subChannel",
        content: code,
      })
    },
    setFillPhone(state, phone) {
      state.fillPhone = phone
      state.pages.fillPhone = phone
      setStroe({
        name: "fillPhone",
        content: phone,
      })
    },
    setActivityId(state, activityId) {
      state.activityId = activityId
      state.pages.activityId = activityId
      setStroe({
        name: "activityId",
        content: activityId,
      })
    },
    setCityCode(state, code) {
      state.cityCode = code
    },
    setScanSeekIds(state, id) {
      state.scanSeekIds.push(id)
      setStroe({
        name: "scanSeekIds",
        content: state.scanSeekIds,
      })
    }
  },
  actions: {
  },
}
export default side