import Vue from 'vue'
import Vuex from 'vuex'
import tabbar from './modules/tabbar'
import user from './modules/userInfo'
import feedback from './modules/feedback'
import getters from './getters'
import pages  from './modules/pages'
import search from './modules/search'
Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    tabbar,
    user,
    feedback,
    pages,
    search
  },
  getters
})
export default store
