<template>
  <div class="box-wrap">
    <div class="heder-box">
      <img
        class="banner-img"
        src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/%E5%BA%95%E5%9B%BE_420286141426778150.png"
        alt=""
      />
      <!-- v-if="channelCode !== 'sllss'" -->
      <img
        @click="playVideo"
        class="play-icon"
        src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/play-icon_409018134985654287.png"
        alt=""
      />
      <img
        class="jgs-logo"
        src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/%E4%B9%9D%E5%85%AC%E5%B1%B1logo_420286141120593984.png"
        alt=""
      />
      <img
        class="banner-logo"
        v-if="false"
        src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/tk-logo_416971384955289699.png"
        alt=""
      />
      <div class="banner-notes">
        <div class="notes-item">折扣优惠</div>
        <div class="notes-item">永久产权</div>
        <div class="notes-item">专车免费接送</div>
      </div>
      <div class="banner-text">
        <p v-if="channelCode !== 'sllss'">陵园+生态旅游模式</p>
        <p v-else>陵园+生态纪念园模式</p>
        <p>售价19800元起</p>
      </div>
    </div>
    <div class="form-box">
      <div class="icon-list">
        <div class="icon-item">
          <img
            class="icom-img"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/formicon1_409002613108064272.png"
            alt=""
          />
          <p class="icon-text">专车接送</p>
        </div>
        <div class="icon-item">
          <img
            class="icom-img"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/formicon2_409002613116452931.png"
            alt=""
          />
          <p class="icon-text">折扣优惠</p>
        </div>
        <div class="icon-item">
          <img
            class="icom-img"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/formicon3_409002613183561745.png"
            alt=""
          />
          <p class="icon-text">殡仪服务</p>
        </div>
        <div class="icon-item">
          <img
            class="icom-img"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/formicon4_409002613183561744.png"
            alt=""
          />
          <p class="icon-text">骨灰寄存</p>
        </div>
      </div>
      <div class="form-item">
        <div class="title-icon">
          <img
            class="left-icon"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/form-icon-l_409002205505601574.png"
            alt=""
          />
          <img
            class="right-icon"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/form-icon-r_409002205505601573.png"
            alt=""
          />
        </div>
        <JgsForm
          :activityId="activityId"
          :startTime="startTime"
          @showAgreement="showAgreementFun"
        />
      </div>
    </div>
    <div class="tel-box">
      <img
        class="tel-img"
        src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/phone-icon_409018134985654288.png"
        alt=""
      />
      <p>19535680571</p>
      <a class="tel-css" @click="telClick" href="tel:19535680571">电话咨询</a>
    </div>
    <div class="content-box">
      <div class="content-item">
        <div class="item-title">
          <p>京城正北中轴线 长城后花园</p>
        </div>
        <div class="item-text">
          <p class="text-css" v-if="channelCode !== 'sllss'">
            全国唯一背靠长城、地处<span>京城中轴线</span>的<span>风水宝地</span>。
          </p>
          <p class="text-css" v-else>
            地理位置俱佳，背靠长城、地处<span>京城中轴线</span>。
          </p>
        </div>
        <div class="item-img">
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/huayuan-1_409003315486212176.png"
              alt=""
            />
            <!-- v-if="channelCode !== 'sllss'" -->
            <img
              @click="playVideo"
              class="play-icon"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/play-icon_409018134985654287.png"
              alt=""
            />
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/huayuan-2_409003315482017845.png"
              alt=""
            />
            <!-- v-if="channelCode !== 'sllss'" -->
            <img
              @click="playVideo"
              class="play-icon"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/play-icon_409018134985654287.png"
              alt=""
            />
          </div>
        </div>
        <h3 class="one-title">陵园介绍：</h3>
        <div class="item-text">
           <!-- <p class="text-css" v-if="channelCode === 'sllss'"> -->
          <p class="text-css" v-if="false">
            泰康集团旗下九公山长城纪念林定鼎京城中轴，落笔天安门正北长城环拥之处，九座青山鼎列。陵园建成于2004年，是<span>北京市民政局批复合法陵园之一</span>。7500亩碧野山水相依，历经十七年运营，精筑“一堂、双湖、三馆、七园”，形成集“艺术化、公园化、现代化”的人文精神纪念园。
          </p>
          <p v-else class="text-css">
            泰康集团旗下九公山长城纪念林定鼎京城中轴，落笔天安门正北长城环拥之处，九座青山鼎列，上风上水可谓之龙脉福泽地。陵园建成于2004年，是<span>北京市民政局批复合法陵园之一</span>。7500亩碧野山水相依，历经十七年运营，精筑“一堂、双湖、三馆、七园”，形成集“艺术化、公园化、现代化”的人文精神纪念园。
          </p>
        </div>
      </div>
      <div class="content-item">
        <div class="item-title">
          <p>生态陵园，撼世景观</p>
        </div>
        <div class="item-banner">
          <img
            class="banner-img"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/jingguan-banner_409009021358653529.png"
          />
        </div>
        <div class="item-swiper">
          <van-swipe
            @change="onChangeSwipe"
            ref="my-swiper"
            class="my-swiper"
            :autoplay="3000"
            :show-indicators="false"
          >
            <van-swipe-item>
              <div class="item-img">
                <div class="img-wrap">
                  <img
                    class="img-css"
                    src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/jingguan-1_409003745834385477.png"
                    alt=""
                  />
                </div>
                <div class="img-wrap">
                  <img
                    class="img-css"
                    src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/jingguan-2_409008058363232275.png"
                    alt=""
                  />
                </div>
                <div class="img-wrap">
                  <img
                    class="img-css"
                    src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/jingguan-3_409008058023493660.png"
                    alt=""
                  />
                </div>
                <div class="img-wrap">
                  <img
                    class="img-css"
                    src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/jingguan-4_409008057847332917.png"
                    alt=""
                  />
                </div>
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="item-img">
                <div class="img-wrap">
                  <img
                    class="img-css"
                    src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/jingguan-5_409008059936096271.png"
                    alt=""
                  />
                </div>
                <div class="img-wrap">
                  <img
                    class="img-css"
                    src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/jingguan-6_409008634429915170.png"
                    alt=""
                  />
                </div>
                <div class="img-wrap">
                  <img
                    class="img-css"
                    src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/jingguan-7_409008634283114557.png"
                    alt=""
                  />
                </div>
                <div class="img-wrap">
                  <img
                    class="img-css"
                    src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/jingguan-8_409008633939181589.png"
                    alt=""
                  />
                </div>
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="item-img">
                <div class="img-wrap">
                  <img
                    class="img-css"
                    src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/jingguan-9_409008633410699270.png"
                    alt=""
                  />
                </div>
                <div class="img-wrap">
                  <img
                    class="img-css"
                    src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/jingguan-10_409008634115342401.png"
                    alt=""
                  />
                </div>
                <div class="img-wrap">
                  <img
                    class="img-css"
                    src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/jingguan-11_409009018921762838.png"
                    alt=""
                  />
                </div>
                <div class="img-wrap">
                  <img
                    class="img-css"
                    src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/jingguan-12_409009021186686987.png"
                    alt=""
                  />
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
          <div class="swipe-indicators">
            <div
              class="indicator-item"
              @click="goSlide(index)"
              :class="{ indicatorActive: index === current }"
              v-for="(item, index) of 3"
              :key="index"
            ></div>
          </div>
        </div>
      </div>
      <div class="content-item">
        <div class="item-title">
          <p>陵园墓型种类</p>
        </div>
        <div class="type-title">
          <div class="type-wrap">
            <h3>自选设计</h3>
            <h4>价格面议</h4>
          </div>
          <div class="type-icon" @click="goKf">
            <img
              class="icon"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/wx-icon_409021511865942109.png"
              alt=""
            />
            <p>在线咨询</p>
          </div>
        </div>
        <div class="item-img">
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/type-1_409020149878964289.png"
              alt=""
            />
            <div class="type-text"><p>艺术设计</p></div>
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/type-2_409020151959339091.png"
              alt=""
            />
            <div class="type-text"><p>艺术设计</p></div>
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/type-3_409020153695780912.png"
              alt=""
            />
            <div class="type-text"><p>艺术设计</p></div>
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/type-4_409020151497965584.png"
              alt=""
            />
            <div class="type-text"><p>艺术设计</p></div>
          </div>
        </div>
        <div class="note-title">
          <p>自选艺术设计</p>
          <span>（双穴、多穴）</span>
        </div>
        <div class="type-title">
          <div class="type-wrap">
            <h3>标准墓</h3>
            <h4>1.98万起/套</h4>
          </div>
          <div class="type-icon" @click="goKf">
            <img
              class="icon"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/wx-icon_409021511865942109.png"
              alt=""
            />
            <p>在线咨询</p>
          </div>
        </div>
        <div class="item-img">
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/type-bz-1_409020476787212353.png"
              alt=""
            />
            <img
              class="rm-icon"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/rm-icon_409018135069540366.png"
              alt=""
            />
            <div class="type-note-text">
              <h3>一路平安</h3>
              <p>双穴、多穴</p>
            </div>
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/type-bz-2_409020477403775064.png"
              alt=""
            />
            <img
              class="rm-icon"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/rm-icon_409018135069540366.png"
              alt=""
            />
            <div class="type-note-text">
              <h3>山水涧</h3>
              <p>多穴</p>
            </div>
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/type-bz-3_409020476971761722.png"
              alt=""
            />
            <img
              class="rm-icon"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/rm-icon_409018135069540366.png"
              alt=""
            />
            <div class="type-note-text">
              <h3>富贵</h3>
              <p>双穴、多穴</p>
            </div>
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/type-bz-4_409020476791406594.png"
              alt=""
            />
            <img
              class="rm-icon"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/rm-icon_409018135069540366.png"
              alt=""
            />
            <div class="type-note-text">
              <h3>守护</h3>
              <p>多穴</p>
            </div>
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/type-bz-5_409020890647576606.png"
              alt=""
            />
            <div class="type-note-text">
              <h3>龙佑</h3>
              <p>多穴</p>
            </div>
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/type-bz-6_409020890588856412.png"
              alt=""
            />
            <div class="type-note-text">
              <h3>圆满人生</h3>
              <p>多穴</p>
            </div>
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/type-bz-7_409020888789500000.png"
              alt=""
            />
            <div class="type-note-text">
              <h3>心语</h3>
              <p>双穴、多穴</p>
            </div>
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/type-bz-8_409020889036963881.png"
              alt=""
            />
            <div class="type-note-text">
              <h3>君子</h3>
              <p>双穴</p>
            </div>
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/type-bz-9_409021277198827593.png"
              alt=""
            />
            <div class="text-icon">高性价</div>
            <div class="type-note-text">
              <h3>生态节地藏</h3>
              <p>双穴</p>
            </div>
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/type-bz-10_409021277387571279.png"
              alt=""
            />
            <div class="text-icon">高性价</div>
            <div class="type-note-text">
              <h3>艺术卧碑</h3>
              <p>双穴</p>
            </div>
          </div>
        </div>
        <div class="type-title">
          <div class="type-wrap">
            <h3>艺术墓</h3>
            <h4>31万起/套</h4>
          </div>
          <div class="type-icon" @click="goKf">
            <img
              class="icon"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/wx-icon_409021511865942109.png"
              alt=""
            />
            <p>在线咨询</p>
          </div>
        </div>
        <div class="item-img">
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/type-ys-1_409021422552432681.png"
              alt=""
            />
            <img
              class="rm-icon"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/rm-icon_409018135069540366.png"
              alt=""
            />
            <div class="type-note-text">
              <h3>莲爱</h3>
              <p>双穴、多穴</p>
            </div>
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/type-ys-2_409021421466107950.png"
              alt=""
            />
            <img
              class="rm-icon"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/rm-icon_409018135069540366.png"
              alt=""
            />
            <div class="type-note-text">
              <h3>一帆风顺</h3>
              <p>双穴、多穴</p>
            </div>
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/type-ys-3_409021421461913626.png"
              alt=""
            />
            <div class="type-note-text">
              <h3>宝德</h3>
              <p>双穴</p>
            </div>
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/type-ys-4_409021421445136453.png"
              alt=""
            />
            <div class="type-note-text">
              <h3>福隆</h3>
              <p>多穴</p>
            </div>
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/type-ys-5_409021513019375627.png"
              alt=""
            />
            <div class="type-note-text">
              <h3>普渡</h3>
              <p>双穴、多穴</p>
            </div>
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/type-ys-6_409021513182953532.png"
              alt=""
            />
            <img
              class="rm-icon"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/rm-icon_409018135069540366.png"
              alt=""
            />
            <div class="type-note-text">
              <h3>松鹤延年</h3>
              <p>双穴、多穴</p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-item">
        <div class="item-title">
          <p>大师手笔，匠筑精品</p>
        </div>
        <div class="item-banner">
          <img
            class="banner-img"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/ds-banner_416001502319165515.png"
            alt=""
          />
          <p class="lb-tip-text">接待大堂</p>
        </div>
        <div class="item-img">
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/ds-1_416001498309410896.png"
              alt=""
            />
            <p class="lb-tip-text">天堂图书馆壁龛</p>
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/ds-2_416001499970355232.png"
              alt=""
            />
            <p class="lb-tip-text">纪念堂水系</p>
          </div>
        </div>
      </div>
      <div class="content-item">
        <div class="item-title">
          <p>九公山舍，百姓农庄</p>
        </div>
        <div class="item-text">
          <p class="text-css">
            陵园+生态田园模式，打造远离喧嚣、禅静雅趣的农庄山舍，<span
              >祭奠亲人的同时享受回归自热和本心。</span
            >
          </p>
        </div>
        <div class="item-img">
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/nz-1_409017533434380325.png"
              alt=""
            />
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/nz-2_409017530963935283.png"
              alt=""
            />
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/nz-3_409017531177844766.png"
              alt=""
            />
          </div>
          <div class="img-wrap">
            <img
              class="img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/nz-4_409017531119124524.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="content-item">
        <div class="item-title">
          <p>高端殡仪服务</p>
          <p v-if="false">高端军礼路殡仪服务</p>
        </div>
        <div class="item-text">
          <p class="text-css">
            九公山长城纪念林拥有专业、规范的下葬及祭祀流程，以优质、周到、<span>高品质的礼仪服务</span>，给逝者尊严，给生者安慰。
          </p>
          <p class="text-css" v-if="false">
            九公山长城纪念林拥有专业、规范的下葬及祭祀流程，<span>礼仪兵队伍均来自三军仪仗队</span>的退役士兵。以优质、周到、<span>高品质的礼仪服务</span>，给逝者尊严，给生者安慰。
          </p>
        </div>
        <template v-if="false">
          <div class="item-banner">
            <img
              class="banner-img"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/jl-banner_409015688460714012.png"
              alt=""
            />
          </div>
          <div class="item-img">
            <div class="img-wrap">
              <img
                class="img-css"
                src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/jl-1_409015690054549551.png"
                alt=""
              />
            </div>
            <div class="img-wrap">
              <img
                class="img-css"
                src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/jl-2_416001499492204628.png"
                alt=""
              />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="item-banner">
            <img
              class="banner-img"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/360-gd-banner_416734546181177346.png"
              alt=""
            />
          </div>
          <div class="item-img">
            <div class="img-wrap">
              <img
                class="img-css"
                src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/360-gd-1_416734544184688688.png"
                alt=""
              />
            </div>
            <div class="img-wrap">
              <img
                class="img-css"
                src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/360-gd-2_416734544184688689.png"
                alt=""
              />
            </div>
          </div>
        </template>
        <h3 class="one-title">八大流程，24小时管家式引导</h3>
        <div class="item-text">
          <p class="text-css">
            <span
              >临终咨询、接运安置、入殓更衣、安灵服务、治丧协调、告别追思、遗体火化、后续关怀</span
            >等八大流程；24小时在线，管家式全程陪同引导。
          </p>
        </div>
        <div class="seek-centenr" @click="goKf">
          <img
            class="icon"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/wx-icon_409021511865942109.png"
            alt=""
          />
          <p>咨询殡仪服务</p>
        </div>
      </div>
      <div class="content-item">
        <div class="item-title">
          <p>代亲祭扫服务</p>
        </div>
        <div class="item-text">
          <p class="text-css">
            <b>服务内容</b>：擦拭墓碑｜摆放供品｜行礼鞠躬｜敬献鲜花｜诵读表文 |
            视频拍摄
          </p>
        </div>
        <div class="item-text">
          <p class="text-css">
            <b>祭扫物品</b>：鲜花｜精致糕点｜水果｜清洁毛巾
          </p>
        </div>
        <div class="three-img">
          <img
            class="img-css"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/fw-1_409002984043921501.png"
            alt=""
          />
          <img
            class="img-css"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/fw-2_409002983855177764.png"
            alt=""
          />
          <img
            class="img-css"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/fw-3_409002984203304969.png"
            alt=""
          />
        </div>
      </div>
      <div class="content-item">
        <div class="lc-content">
          <h3 class="title-text">购墓流程</h3>
          <div class="lc-img-list">
            <div class="lc-img-wrap">
              <img
                class="lc-img-css"
                src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-1_409016640756465749.png"
                alt=""
              />
              <p>预约咨询</p>
            </div>
            <div class="lc-img-icon">
              <img
                class="lc-icon-css"
                src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-right_409016921233768454.png"
                alt=""
              />
            </div>
            <div class="lc-img-wrap">
              <img
                class="lc-img-css"
                src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-2_409016640777437192.png"
                alt=""
              />
              <p>专车接送</p>
            </div>
            <div class="lc-img-icon">
              <img
                class="lc-icon-css"
                src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-right_409016921233768454.png"
                alt=""
              />
            </div>
            <div class="lc-img-wrap">
              <img
                class="lc-img-css"
                src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-3_409016640794214499.png"
                alt=""
              />
              <p>现场勘选</p>
            </div>
            <div class="lc-img-icon">
              <img
                class="lc-icon-css"
                src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-right_409016921233768454.png"
                alt=""
              />
            </div>
            <div class="lc-img-wrap">
              <img
                class="lc-img-css"
                src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-4_409016921107939328.png"
                alt=""
              />
              <p>合同签署</p>
            </div>
          </div>
          <div class="lc-img-list last">
            <div class="lc-img-wrap">
              <img
                class="lc-img-css"
                src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-5_409016921103745078.png"
                alt=""
              />
              <p>墓碑制作</p>
            </div>
            <div class="lc-img-icon">
              <img
                class="lc-icon-css"
                src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-right_409016921233768454.png"
                alt=""
              />
            </div>
            <div class="lc-img-wrap">
              <img
                class="lc-img-css"
                src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-6_409016921116327939.png"
                alt=""
              />
              <p>办理安葬</p>
            </div>
            <div class="lc-img-icon">
              <img
                class="lc-icon-css"
                src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-right_409016921233768454.png"
                alt=""
              />
            </div>
            <div class="lc-img-wrap">
              <img
                class="lc-img-css"
                src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-7_409016921250545743.png"
                alt=""
              />
              <p>售后服务</p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-item">
        <h3 class="title-text">常见问题</h3>
        <div class="note-text">
          <img
            class="note-icon"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/note-icon_409017529097470006.png"
            alt=""
          />
          <div class="note-text-css">
            <h3>除了购墓费用外，还有哪些费用？</h3>
            <p>
              首次购买墓地除了购墓费用外，一般还需缴纳刻字费、20年管理费，大约2000-5000元。
            </p>
          </div>
        </div>
        <div class="note-text">
          <img
            class="note-icon"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/note-icon_409017529097470006.png"
            alt=""
          />
          <div class="note-text-css">
            <h3>九公山墓地是合法的吗？是否有使用期限？</h3>
            <p>
              九公山是北京市民政局批复的33家合法陵园之一，陵园墓地可以永久使用。
            </p>
          </div>
        </div>
        <div class="note-text">
          <img
            class="note-icon"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/note-icon_409017529097470006.png"
            alt=""
          />
          <div class="note-text-css">
            <h3>有合规安葬证吗？</h3>
            <p>购墓后直接由陵园发放公墓安葬证，证件按相关规定合法签发。</p>
          </div>
        </div>
        <div class="note-text">
          <img
            class="note-icon"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/note-icon_409017529097470006.png"
            alt=""
          />
          <div class="note-text-css">
            <h3>你们是陵园直售的吗？</h3>
            <p>
              是的，陵园业务经理直接带您现场参观勘选，您直接向陵园购买墓地，并跟陵园签订纸质合同。
            </p>
          </div>
        </div>
        <div class="seek-centenr" @click="goKf">
          <img
            class="icon"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/wx-icon_409021511865942109.png"
            alt=""
          />
          <p>咨询更多问题</p>
        </div>
      </div>
    </div>
    <div class="b-a-box">
      <a class="beian" href="https://beian.miit.gov.cn/#/home">蜀ICP备2021003234号</a>
      <p @click="goHome">成都众兴未来科技有限公司<span>查看公司简介</span></p>
    </div>
    <div class="fixed-box" v-show="showGoTop">
      <a class="tel-btn" @click="telClick" href="tel:19535680571">
        <img
          class="tel-icon"
          src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/tel-icon_416003267152265254.png"
          alt=""
        />
        <p>电话咨询</p></a
      >
      <div class="form-btn" @click="showModalFun">立即预约看墓</div>
    </div>
    <van-action-sheet :closeable="true" v-model="showModal" title="">
      <div class="modal-content">
        <van-icon class="close-icon" @click="closeFun" name="cross" />
        <JgsForm
          :activityId="activityId"
          :startTime="startTime"
          @showAgreement="showAgreementFun"
        />
      </div>
    </van-action-sheet>
    <van-overlay z-index="2" :show="showVideo" @click="hideVideoFun" />

    <div class="videoModal" v-show="showVideo">
      <div class="videoModalContent">
        <video
          class="videoCss"
          id="video"
          src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/%E4%B9%9D%E5%85%AC%E5%B1%B1%E5%AE%A3%E4%BC%A0%E7%89%87-33M_410386923698470926.mp4"
          controls="true"
          preload="auto"
          webkit-playsinline="true"
          playsinline="true"
          x-webkit-airplay="allow"
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          x5-video-orientation="portraint"
          style="object-fit: fill"
          autoplay
        ></video>
      </div>
      <van-icon name="close" class="videoModalClose" @click="hideVideoFun" />
    </div>
    <van-overlay
      z-index="2"
      :show="showAgreement"
      @click="showAgreement = false"
    />
    <van-action-sheet v-model="showAgreement" title="用户隐私协议">
      <div class="agreementContent">
        <p>
          本站非常重视用户信息的保护，在使用本站的所有产品和服务前，请您务必仔细阅读并透彻理解本协议内容。一旦您选择使用，即表示您认可并接受本协议条款现有内容及其可能随时更新的内容。
        </p>
        <p>
          本协议仅适用于【北京市长城纪念林有限公司】及其关联公司授权【成都众兴未来科技有限公司】（本主体）提供的系列产品或服务。（上述主体合称“信息使用方”）
        </p>
        <p>
          本主体提供的产品和服务的运营商及其关联公司深知个人信息对您的重要性，并会尽力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，本站承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
        </p>
        <p>
          请在使用我们的产品（或服务）前，仔细阅读并了解本《用户隐私协议》。
        </p>
        <p>一、如何收集和使用您的个人信息</p>
        <p>
          个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息
        </p>
        <p>本站仅会出于本协议所述的以下目的，收集和使用您的个人信息：</p>
        <p>（一）为您提供的基本服务</p>
        <p>1、业务功能一：注册成为用户。</p>
        <p>
          为完成创建账号，您需提供以下信息：您的姓名、电子邮箱地址、创建的用户名和密码。在注册过程中，如果您提供以下额外信息，将有助于我们给您提供更好的服务和体验：手机号、工作职位、公司、教育背景。但如果您不提供这些信息，将不会影响使用本服务的基本功能。
        </p>
        <p>2、业务功能二：发送促销营销信息。</p>
        <p>
          为向您推荐适合的服务，您需提供以下信息：您的姓名、电话、电子邮箱地址、用户名。但法律法规不允许本站进行推荐的，本站对相关产品向您发送营销信息。您可以依据本协议接收或拒绝接收相关营销信息。
        </p>
        <p>3、业务功三：支付结算。</p>
        <p>
          为完成线上支付服务，您需提供以下信息：您的姓名、电话、身份证号码、电子支付账号。
        </p>
        <p>4、业务功能四：交付产品或服务。</p>
        <p>
          为向您配送所购买的商品，您需提供以下信息：您的姓名、电话、联系地址。
        </p>
        <p>（二）推荐产品或服务</p>
        <p>
          在法律法规和行政主管部门允许的前提下，本站将根据您的主动申请向您推荐合适产品或服务。为进行推荐，您需提供以下信息的部分或全部：您的姓名、性别、手机号、验证码、身份证号、年龄、出生日期、以及和申请产品或服务相关的内容信息。
        </p>
        <p>
          在推荐过程中，如果您提供以下额外信息的部分或全部，将有助于信息使用方给您提供更好的服务和体验：出行方式、子女情况、收入情况、兴趣爱好、身体健康状况、房产情况、机动车保有情况、信用卡申请情况、保单情况、工作职位、公司、教育背景等。但如果您不提供这些信息，将不会影响使用相关服务的基本功能。
        </p>
        <p>
          向第三方推荐您的信息时，我们会按照第三章“我们如何共享、转让、公开披露您的个人信息”的要求进行，并按照本隐私协议的规定保护您的合法权益。
        </p>
        <p>
          特别声明，根据相关法律法规或行政主管部门的要求，部分产品或服务不允许本站进行推荐。但如果您仍然对相关产品或服务有交易意愿时，本站将就相关产品向您提供广告或营销推广信息，但不对产品或服务的内容进行任何形式的推荐；您可以根据相关的广告或营销推广信息自行选择合适的产品或服务。
        </p>
        <p>（三）开展内部审计、数据分析和研究，改善我们的产品或服务</p>
        <p>
          为了规范本站的用户信息保护，为了向您和其他用户提供更好的服务，我们会使用您所提供的信息进行分析和研究。我们进行分析和研究时，会对您的信息进行去标识化处理。
        </p>
        <p>（四）保险及养老相关服务</p>
        <p>
          如果您通过本站提供的营销推广信息进行保险或养老相关的产品、服务交易，您明确同意，您的【姓名、手机号码】等个人信息将提供给最终向您提供服务的保险公司、养老公司或提供该等服务的主体。
        </p>
        <p>（五）其他</p>
        <p>当我们要将信息用于本协议未载明的其它用途时，会事先征求您的同意。</p>
        <p>
          当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。
        </p>
        <p>
          您提供的上述信息，将在您使用本服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除上述信息。
        </p>
        <p>二、我们如何使用 Cookie 和同类技术</p>
        <p>（一）Cookie</p>
        <p>
          为确保网站正常运转，我们会在您的计算机或移动设备上存储名为 Cookie
          的小数据文件。Cookie
          通常包含标识符、站点名称以及一些号码和字符。借助于
          Cookie，网站能够存储您的偏好或购物篮内的商品等数据。
        </p>
        <p>
          我们不会将 Cookie
          用于本协议所述目的之外的任何用途。您可根据自己的偏好管理或删除
          Cookie。有关详情，请参见
          AboutCookies.org。您可以清除计算机上保存的所有
          Cookie，大部分网络浏览器都设有阻止 Cookie
          的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。
        </p>
        <p>（二）网站信标和像素标签</p>
        <p>
          除 Cookie
          外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
        </p>
        <p>（三）Do Not Track（请勿追踪）</p>
        <p>
          很多网络浏览器均设有 Do Not Track 功能，该功能可向网站发布 Do Not
          Track
          请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了
          Do Not Track，那么我们的所有网站都会尊重您的选择。
        </p>
        <p>三、我们如何共享、转让、公开披露您的个人信息:</p>
        <p>（一）共享</p>
        <p>
          我们不会与信息使用方以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：
        </p>
        <p>
          （1）在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息；
        </p>
        <p>
          （2）我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息；
        </p>
        <p>
          （3）与我们的附属公司、关联公司共享：为达成本隐私协议第一章项下的服务目的，您的个人信息可能会与成都众兴未来科技有限公司的附属公司、关联公司共享，我们只会共享必要的个人信息，且受本隐私协议中所声明目的的约束，附属公司、关联公司如要改变个人信息的处理目的，将再次征求您的授权同意；
        </p>
        <p>
          （4）与授权合作伙伴共享：仅为实现本协议中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。
        </p>
        <p>
          对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私协议以及其他相关的保密和安全措施来处理个人信息。
        </p>
        <p>（二）转让</p>
        <p>
          我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
        </p>
        <p>
          1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
        </p>
        <p>
          2、在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私协议的约束，否则我们将要求该公司、组织重新向您征求授权同意。
        </p>
        <p>（三）公开披露</p>
        <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
        <p>1、获得您明确同意后；</p>
        <p>
          2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
        </p>
        <p>四、我们如何保护您的个人信息</p>
        <p>
          （一）我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。
        </p>
        <p>
          （二）我们会采取合理可行的措施，确保未收集无关的个人信息。我们只会在达成本协议所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
        </p>
        <p>
          （三）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。
        </p>
        <p>
          （四）我们将定期更新并公开安全风险、个人信息安全影响评估等报告的有关内容。您可通过查看网站公告的方式获得。
        </p>
        <p>
          （五）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。
        </p>
        <p>
          （六）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
        </p>
        <p>
          同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
        </p>
        <p>五、您的权利</p>
        <p>
          按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
        </p>
        <p>（一）访问您的个人信息</p>
        <p>
          您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：
        </p>
        <p>
          账户信息——如果您希望访问或编辑您的账户中的个人资料信息和支付信息、更改您的密码、添加安全信息或关闭您的账户等，您可以通过访问个人账户的管理页面行此类操作。
        </p>
        <p>
          如果您无法通过上述链接访问这些个人信息，您可以随时联系本站客服。我们将在30天内回复您的访问请求。
        </p>
        <p>
          对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。
        </p>
        <p>（二）更正您的个人信息</p>
        <p>
          当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以通过“（一）访问您的个人信息”中罗列的方式提出更正申请。
        </p>
        <p>（三）删除您的个人信息</p>
        <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
        <p>1、如果我们处理个人信息的行为违反法律法规；</p>
        <p>2、如果我们收集、使用您的个人信息，却未征得您的同意；</p>
        <p>3、如果我们处理个人信息的行为违反了与您的约定；</p>
        <p>4、如果您不再使用我们的产品或服务，或您注销了账号；</p>
        <p>5、如果我们不再为您提供产品或服务。</p>
        <p>
          若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
        </p>
        <p>
          当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
        </p>
        <p>（四）改变您授权同意的范围</p>
        <p>
          每个业务功能需要一些基本的个人信息才能得以完成（见本协议“第一部分”）。对于额外收集的个人信息的收集和使用，您可以随时联系本站客户，给予或收回您的授权同意。我们将在30天内回复您的变更请求。
        </p>
        <p>
          当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
        </p>
        <p>如果您不想接受我们给您发送的商业广告，您随时可通过以下方式取消：</p>
        <p>（1）使用短信或电子邮件中的退订；</p>
        <p>
          （2）若您无法使用（1）中的方式取消，您可以随时联系本站客服，要求退订我们的商业广告，我们将在30天内回复您的取消请求。
        </p>
        <p>（五）个人信息主体注销账户</p>
        <p>
          您随时可注销此前注册的账户，您可以访问个人账户管理页面注销您的账户。
        </p>
        <p>
          在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。
        </p>
        <p>（六）个人信息主体获取个人信息副本</p>
        <p>
          您有权获取您的个人信息副本，您可以随时联系本站客服，我们将在30天内回复您的获取请求。
        </p>
        <p>（七）约束信息系统自动决策</p>
        <p>
          在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。
        </p>
        <p>（八）第三方信息处理</p>
        <p>
          在您明确同意将信息交给第三方以提供产品或服务后，若您想要变更、删除第三方所保有的您的个人信息，您可以向第三方发送变更、删除您个人信息的通知，亦可要求本站为您向第三方转达变更、删除您个人信息的通知。
        </p>
        <p>
          但您知晓并认可，除本站的关联公司外，第三方是否变更、删除您的个人信息，不本站级相关主体的控制。您承诺，只要本站向第三方共享、转让或披露您个人信息之时不违反法律法规，您与第三方之间就您个人信息产生的纠纷，您不会要求本站承担相应责任，但本站将配合您的合理维权。
        </p>
        <p>（九）响应您的上述请求</p>
        <p>
          为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
        </p>
        <p>
          我们将在三十天内做出答复。如您不满意，还可以通过本站产品页面上载明的联系方式进行申诉。
        </p>
        <p>
          对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
        </p>
        <p>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
        <p>1、与国家安全、国防安全直接相关的；</p>
        <p>2、与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p>3、与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
        <p>4、有充分证据表明您存在主观恶意或滥用权利的；</p>
        <p>5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。</p>
        <p>6、涉及商业秘密的。</p>
        <p>六、我们如何处理未成年人的个人信息</p>
        <p>
          我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，未成年人不得创建自己的用户账户。
        </p>
        <p>
          对于经父母同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。
        </p>
        <p>
          尽管当地法律和习俗对未成年人的定义不同，但我们将不满
          18周岁的任何人均视为未成年人。
        </p>
        <p>
          如果我们发现自己在未事先获得可证实的父母同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。
        </p>
        <p>七、本协议如何更新</p>
        <p>我们的隐私协议可能变更。</p>
        <p>
          未经您明确同意，我们不会削减您按照本隐私协议所应享有的权利。我们会在本页面上发布对本协议所做的任何变更。
        </p>
        <p>
          对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件发送通知，说明隐私协议的具体变更内容）。
        </p>
        <p>本协议所指的重大变更包括但不限于：</p>
        <p>
          1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
        </p>
        <p>2、个人信息共享、转让或公开披露的主要对象发生变化；</p>
        <p>3、您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
        <p>4、个人信息安全影响评估报告表明存在高风险时。</p>
        <p>
          本《用户隐私协议》是本站及相关主体系列产品用户协议的组成部分。因服务内容的特殊性，如您不接受我们基于本协议采集处理您的个人信息，或者您不明白或不接受本《用户隐私协议》的内容，我们客观上将无法为您提供后续服务。
        </p>
      </div>
    </van-action-sheet>
    <div class="agreementModal"></div>
    <div class="kf-item" @click="goKf">在线客服</div>
  </div>
</template>

<script>
// @ is an alias to /src
import { setChannel } from "@/util/setChannel.js";
// import wxapi from "@/util/wx.js";
import { collectPointApi, getActivityByCodeApi } from "@/api/apiList.js";
import JgsForm from "@/views/tfhd/jiugongshan/components/form.vue";
import { mapGetters } from "vuex";
// import wx from "weixin-js-sdk";
export default {
  name: "jiugongshan",
  components: { JgsForm },
  data() {
    return {
      current: 0,
      showModal: false,
      showVideo: false,
      activityId: "",
      startTime: 0,
      collectPointParams: {},
      showGoTop: false,
      showAgreement: false,
      videoPalyInitFlag: true,
    };
  },
  created() {
    this.startTime = +new Date();
    setChannel(this);
    this.getActivityByCode().then(() => {
      // 浏览事件
      this.buryingPage(6);
    });
  },
  computed: {
    ...mapGetters(["pages", "UUID"]),
    swiper() {
      return this.$refs["my-swiper"];
    },
    channelCode() {
      return this.pages.channel || "moren";
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true); // 这里加上true是为了保证浏览器滚动的及时性
    document.getElementById("video").pause();
  },
  methods: {
    playVideo() {
      this.buryingPage(4);
      var ua = navigator.userAgent.toLowerCase();
      var isWeixin = ua.indexOf("micromessenger") != -1;
      let wx = window.wx
      this.showVideo = true;
      // 配置
      if (this.videoPalyInitFlag) {
        this.videoPalyInitFlag = false;
        if (isWeixin) {
          // 上面配置错误也无所谓的，即使配置失败，也会走 ready 函数
          // 主要目的就是为了走 ready 函数
          wx.config({
            debug: false,
            appId: "wx123456789",
            timestamp: "",
            nonceStr: "",
            signature: "",
            jsApiList: [],
          });
          wx.ready(() => {
            console.log("$wx.ready");
            // 取得播放器对象，调用播放事件
            document.getElementById("video").play();
          });
        } else {
          document.getElementById("video").play();
        }
        //  document.getElementById("video").play();
      } else {
        document.getElementById("video").play();
      }
    },
    hideVideoFun() {
      document.getElementById("video").pause();
      this.showVideo = false;
    },
    onChangeSwipe(index) {
      this.current = index;
    },
    goSlide(index) {
      this.swiper.swipeTo(index);
    },
    goKf() {
      this.buryingPage(2);
      window.location.href =
        "https://work.weixin.qq.com/kfid/kfc51653415422b34fa";
    },
    telClick() {
      this.buryingPage(3);
    },
    closeFun() {
      this.showModal = false;
    },
    showModalFun() {
      this.showVideo = false;
      setTimeout(() => {
        this.showModal = true;
      }, 100);
    },
    // 通过子渠道code获取活动id
    getActivityByCode() {
      return new Promise((resolve) => {
        if (this.$route.params.subchannel) {
          getActivityByCodeApi({
            code: this.$route.params.subchannel,
          }).then((res) => {
            this.activityId = res.data.data;
            resolve();
          });
        } else {
          resolve();
        }
      });
    },
    // 埋点
    buryingPage(type) {
      this.collectPointParams = {
        channelCode: this.pages.channel,
        subChannelCode: this.pages.subChannel,
        activityId: this.activityId,
        eventType: type,
        uuid: this.UUID,
        localUrl: location.href,
      };
      if (this.pages.subChannel && this.pages.channel) {
        collectPointApi(this.collectPointParams)
          .then((res) => {
            console.log(res, "埋点成功");
          })
          .catch((err) => {
            console.log(err, "买点失败");
          });
      }
    },
    // 监听滚动超过一屏
    handleScroll() {
      var scrolltop =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
      if (scrolltop >= 305) {
        // 浏览器窗口的内部高度 window.innerHeight
        this.showGoTop = true; // 超过就显示出来
      } else {
        this.showGoTop = false; // 不超过还藏起来
      }
    },
    showAgreementFun() {
      this.showAgreement = true;
    },
    checkSign() {
      // window.__wxjs_is_wkwebview为true 时 为 IOS 设备 false时 为 安卓 设备
      if (window.__wxjs_is_wkwebview) {
        // IOS
        if (window.entryUrl == "" || window.entryUrl == undefined) {
          var url = location.href.split("#")[0];
          window.entryUrl = url; // 将后面的参数去除
        }
        // wxapi.wxRegister(location.href.split("#")[0], "ios");
      } else {
        // 安卓
        // setTimeout(function () {
        //   wxapi.wxRegister(location.href.split("#")[0], "android");
        // }, 500);
      }
    },
    goHome() {
      this.$router.push("/about");
    },
  },
  destroyed() {},
  deactivated() {},
};
</script>
<style lang="scss" scoped>
img {
  pointer-events: none;
}
img.play-icon {
  pointer-events: auto;
}
.box-wrap {
  min-height: 100vh;
  box-sizing: border-box;
  background: #fff;
  padding-bottom: calc(1.46rem + env(safe-area-inset-bottom));
  overflow-y: auto;
  .heder-box {
    height: 6.1rem;
    position: relative;
    .banner-img {
      display: block;
      width: 100%;
      height: 100%;
    }
    .play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0.72rem;
      height: 0.72rem;
      transform: translateX(-50%) translateY(30%);
    }
    .banner-logo {
      position: absolute;
      top: 0.16rem;
      right: 0.12rem;
      width: 1.99rem;
      height: 0.36rem;
    }
    .jgs-logo {
      position: absolute;
      top: 0.16rem;
      left: 0.12rem;
      width: 0.94rem;
      height: 0.36rem;
    }
    .banner-notes {
      position: absolute;
      top: 2.4rem;
      left: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .notes-item {
        padding: 2px 0.1rem 0;
        background: linear-gradient(
          180deg,
          rgba(235, 54, 89, 0.8) 0%,
          rgba(185, 27, 63, 0.8) 100%
        );
        border-radius: 0.04rem;
        font-size: 0.24rem;
        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.36rem;
        margin-right: 0.24rem;
      }
      .notes-item:last-of-type {
        margin-right: 0;
      }
    }
    .banner-text {
      position: absolute;
      bottom: 0.18rem;
      left: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 0.6rem;
      p {
        font-size: 0.28rem;
        color: #ebcbb0;
        font-style: oblique;
        font-weight: 500;
      }
    }
  }
  .form-box {
    padding: 0.4rem 0.48rem;
    .icon-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon-item {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        width: 1.16rem;
        .icom-img {
          width: 0.9rem;
          height: 0.9rem;
        }
        .icon-text {
          padding-top: 0.12rem;
          font-size: 0.28rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 0.4rem;
        }
      }
    }
    .form-item {
      position: relative;
      padding-top: 0.3rem;
      .title-icon {
        position: absolute;
        top: 0.44rem;
        left: 0;
        width: 100%;
        .left-icon {
          position: absolute;
          top: 0.1rem;
          left: -0.48rem;
          width: 1.1rem;
          height: 0.8rem;
        }
        .right-icon {
          position: absolute;
          top: 0;
          right: -0.48rem;
          width: 0.93rem;
          height: 0.63rem;
        }
      }
    }
  }
  .tel-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -0.16rem;
    .tel-img {
      width: 0.44rem;
      height: 0.4rem;
    }
    p {
      font-size: 0.42rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #b91b3f;
      padding: 0 0.12rem;
    }
    .tel-css {
      font-size: 0.36rem;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #0379fb;
      font-weight: bold;
      text-decoration: underline;
    }
  }
  .content-box {
    padding: 0.24rem;
    padding-bottom: 0.36rem;
    .content-item {
      padding-top: 0.64rem;
      .item-title {
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          position: relative;
          display: inline-flex;
          font-size: 0.42rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 0.6rem;
          padding-left: 0.92rem;
          padding-right: 0.9rem;
        }
        p::after,
        p::before {
          position: absolute;
          top: 0;
          width: 0.88rem;
          height: 0.53rem;
          content: "";
          background-size: 100%;
          background-position: center;
          background-repeat: no-repeat;
        }
        p::after {
          background-image: url(https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/title-icon-r_409019173403050054.png);
          right: 0;
        }
        p::before {
          background-image: url(https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/title-icon-l_409019173398855768.png);
          left: 0;
        }
      }
      .item-text {
        padding-top: 0.12rem;
        .text-css {
          font-size: 0.3rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 0.42rem;
          span {
            color: #b91b3f;
          }
          b {
            font-weight: bold;
          }
        }
      }
      .item-img {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 0.24rem;
        .img-wrap {
          position: relative;
          width: 3.4rem;
          border-radius: 0.04rem;
          overflow: hidden;
          .img-css {
            display: block;
            width: 100%;
          }
          .play-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0.52rem;
            height: 0.52rem;
            transform: translateX(-50%) translateY(-40%);
          }
          .note-icon {
            position: absolute;
            top: 0.16rem;
            left: 0;
            width: 0.88rem;
            height: 0.3rem;
          }
          .rm-icon {
            position: absolute;
            top: 0.16rem;
            left: 0;
            width: 0.53rem;
            height: 0.3rem;
          }
          .type-note-text {
            position: absolute;
            bottom: 0.22rem;
            left: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 0 0.16rem;
            h3 {
              font-size: 0.28rem;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #6c340d;
            }
            p {
              font-size: 0.24rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }
          }
          .type-text {
            position: absolute;
            top: 0.16rem;
            left: 0;
            display: flex;
            p {
              display: inline-flex;
              font-size: 0.18rem;
              color: #fff;
              background: url(https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/text-bg_420288169616031767.png)
                no-repeat center;
              background-size: 100%;
              padding: 0.06rem 0.12rem 0.06rem 0.06rem;
              line-height: 1;
            }
          }
          .text-icon {
            position: absolute;
            top: 0.16rem;
            left: 0;
            width: 0.7rem;
            height: 0.3rem;
            background: linear-gradient(180deg, #eb3659 0%, #b91b3f 100%);
            font-size: 0.18rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0 0.3rem 0.3rem 0;
          }
        }
        .img-wrap:nth-of-type(n + 3) {
          margin-top: 0.18rem;
        }
      }
      .one-title {
        padding-top: 0.18rem;
        font-size: 0.32rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.45rem;
      }
      .item-banner {
        position: relative;
        width: 100%;
        margin-top: 0.34rem;
        .banner-img {
          display: block;
          width: 100%;
        }
      }
      .item-swiper {
        .swipe-indicators {
          padding-top: 0.24rem;
          display: flex;
          align-items: center;
          justify-content: center;
          .indicator-item {
            width: 0.48rem;
            height: 0.06rem;
            background: #db3b21;
            border-radius: 0.03rem;
            opacity: 0.2;
            margin-right: 0.12rem;
          }
          .indicator-item :last-of-type {
            margin-right: 0;
          }
          .indicator-item.indicatorActive {
            background: #db3b21;
            opacity: 1;
          }
        }
      }
      .type-title {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        padding-top: 0.32rem;
        .type-wrap {
          display: flex;
          align-items: baseline;
          h3 {
            font-size: 0.36rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
          h4 {
            padding-left: 0.12rem;
            font-size: 0.26rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #b91b3f;
          }
        }
        .type-icon {
          display: flex;
          .icon {
            width: 0.36rem;
            height: 0.3rem;
          }
          p {
            padding-left: 0.09rem;
            font-size: 0.3rem;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #0379fb;
            font-weight: bold;
            text-decoration: underline;
          }
        }
      }
      .note-title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.26rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.38rem;
        padding-top: 0.12rem;
        span {
          color: #999999;
        }
      }
      .lb-tip-text {
        position: absolute;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 0px 0px 0px 0.12rem;
        display: inline-block;
        font-size: 0.26rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.38rem;
        padding: 0.04rem 0.12rem;
      }
      .seek-centenr {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0.24rem;
        .icon {
          width: 0.4rem;
        }
        p {
          padding-left: 0.08rem;
          font-size: 0.3rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: bold;
          color: #0379fb;
          text-decoration: underline;
        }
      }
      .three-img {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 0.24rem;
        .img-css {
          width: 2.22rem;
        }
      }
      .lc-content {
        padding: 0.36rem 0.5rem 0.5rem;
        background: linear-gradient(
          180deg,
          rgba(246, 217, 193, 0.12) 0%,
          rgba(211, 167, 126, 0.12) 100%
        );
        // opacity: 0.12;
        display: flex;
        align-items: center;
        flex-direction: column;
        .title-text {
          font-size: 0.42rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 0.6rem;
        }
        .lc-img-list {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-top: 0.36rem;
          .lc-img-wrap {
            display: flex;
            align-items: center;
            flex-direction: column;
            .lc-img-css {
              width: 0.8rem;
              height: 0.8rem;
            }
            p {
              font-size: 0.26rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #6c340d;
              line-height: 0.38rem;
              padding-top: 0.09rem;
            }
          }
          .lc-icon-css {
            display: block;
            width: 0.34rem;
            margin-top: 0.3rem;
          }
        }
        .last.lc-img-list {
          justify-content: center;
          .lc-img-icon {
            margin: 0 0.28rem;
          }
        }
      }
      .title-text {
        font-size: 0.42rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.6rem;
        text-align: center;
      }
      .note-text {
        display: flex;
        padding-top: 0.32rem;
        .note-icon {
          width: 0.32rem;
          height: 0.32rem;
          margin-right: 0.12rem;
        }
        .note-text-css {
          width: 0;
          flex: 1;
        }
        h3 {
          font-size: 0.3rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 0.42rem;
        }
        p {
          padding-top: 0.12rem;
          font-size: 0.3rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 0.42rem;
        }
      }
    }
    .content-item:first-of-type {
      padding-top: 0.18rem;
    }
  }
  .b-a-box {
    padding: 0.24rem 0;
    background: #f7f7f7;
    text-align: center;
    p {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #c0c0c0;
      line-height: 0.36rem;
      span {
        padding-left: 0.1rem;
        color: #0379fb;
        text-decoration: underline;
      }
    }
    b {
      position: fixed;
    }
  }
  .fixed-box {
    position: fixed;
    left: 50%;
    width: 7.5rem;
    // height: 1.36rem;
    bottom: 0;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0.24rem;
    box-shadow: 0px -0.02rem 0.04rem 0px rgba(0, 0, 0, 0.07);
    background: #fff;
    padding-bottom: calc(0.24rem + env(safe-area-inset-bottom));
    z-index: 9;
    .tel-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 2.44rem;
      height: 0.98rem;
      background: linear-gradient(to bottom, #fce976 0%, #fdcc33 100%);
      border-radius: 8px;
      box-sizing: border-box;
      padding: 0 0.24rem;
      .tel-icon {
        width: 0.44rem;
        height: 0.4rem;
      }
      p {
        font-size: 0.36rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #6c340d;
      }
    }
    .form-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4.34rem;
      height: 0.98rem;
      background: linear-gradient(180deg, #eb3659 0%, #b91b3f 100%);
      border-radius: 8px;
      font-size: 0.36rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .kf-item {
    position: fixed;
    right: 0;
    top: 4rem;
    width: 0.54rem;
    height: 2.04rem;
    background: url(https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/kf-bg_409016003926900800.png)
      no-repeat center;
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.14rem;
    box-sizing: border-box;
    font-size: 0.3rem;
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    font-weight: 400;
    color: #6c340d;
  }
  .modal-content {
    position: relative;
    padding: 0.48rem;
    padding-bottom: calc(0.48rem + env(safe-area-inset-bottom));
    .close-icon {
      position: absolute;
      top: 0.24rem;
      right: 0.24rem;
      font-size: 0.32rem;
      color: #999;
    }
  }
  .videoModal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 7.5rem;
    height: 100vh;
    transform: translate(-50%, -50%);
    background-color: transparent;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 1rem;
    .videoModalContent {
      width: 100%;
      height: 5rem;
      .videoCss {
        width: 100%;
        height: 100%;
      }
    }
    .videoModalClose {
      margin-top: 1rem;
      color: #fff;
      font-size: 0.9rem;
    }
  }
  .agreementContent {
    padding-bottom: env(safe-area-inset-bottom);
    max-height: 70vh;
    overflow-y: auto;
    padding: 0.2rem;
    p {
      text-indent: 2em;
      font-size: 0.3rem;
      line-height: 0.5rem;
    }
  }
}
</style>
