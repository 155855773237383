<template>
  <div class="box-wrap">
    <div class="header" v-if="!notjgs">
      <img src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/logo_409032635277590534.png" alt="" />
    </div>
    <div class="contentBox">
      <div class="contentItem">
        <div class="itemCss">
          <template v-if="repeatFlag === '0'">
            <div class="successTitle">
              <img
                class="titleIcon"
                src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/success-icon_409032664297979920.png"
                alt=""
              />
              <p>问卷已完成</p>
            </div>
            <div class="textCss">
              <p>我们将安排专属客户经理致电联系您，为您</p>
              <p>安排<span>专车上门接送</span>参观</p>
              <h3>请您注意接听来电~</h3>
            </div>
          </template>
          <template v-else>
            <div class="repeatTitle">重复提交！</div>
            <div class="textCss">
              <p>您已提交过预约参观，我们将安排专属客户</p>
              <p>经理致电联系您安排<span>专车上门接送</span></p>
              <h3>请您注意接听来电~</h3>
            </div>
          </template>
        </div>
        <div class="tipsItem">
          <div class="tipsText" v-if="repeatFlag === '1'">
            <p>如有疑问，可微信扫描下方二维码，添加</p>
            <p>顾问企业微信进行咨询</p>
          </div>
          <div class="qrCodeItem">
            <img
              class="qrCodeImage"
              src="@/assets/img/result/qrcode.png"
              alt=""
            />
          </div>
          <div class="saveItem">
            <p>长按保存二维码图片</p>
            <img
              class="handIcon"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/hand-icon_409032635130789922.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "successJgs",
  data() {
    return {
      repeatFlag: 1,
      notjgs: false
    };
  },
  created() {
    this.repeatFlag = this.$route.query.repeat;
    this.notjgs = this.$route.query.notjgs || false
    console.log(this.repeatFlag, " this.repeatFla");
    console.log(this.repeatFlag);
   
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
.box-wrap {
  .header {
    padding: 0.2rem 0.3rem;
    img {
      display: block;
      width: 100%;
      height: 0.36rem;
    }
  }
  .contentBox {
    width: 100%;
    height: calc(100vh - 0.68rem);
    background: #ddbb9b url(https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/res-bg_409032664365088814.png)
      no-repeat center;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 0.3rem;
    .contentItem {
      width: 100%;
      height: 10.52rem;
      background: url(https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/comtent-bg_409032635126595633.png)
        no-repeat center;
      background-size: 100%;
      .itemCss {
        width: 100%;
        height: 3.96rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        .successTitle {
          padding-top: 0.36rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          .titleIcon {
            display: block;
            width: 0.6rem;
          }
          p {
            padding-top: 0.16rem;
            font-size: 0.36rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #6c340d;
            line-height: 0.5rem;
          }
        }
        .repeatTitle {
          padding-top: 0.52rem;
          font-size: 0.36rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #6c340d;
          line-height: 0.5rem;
        }
        .textCss {
          padding-top: 0.28rem;
          text-align: center;
          p {
            font-size: 0.32rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 0.45rem;
            span {
              color: #b91b3f;
            }
          }
          h3 {
            padding-top: 0.16rem;
            font-size: 0.32rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 0.45rem;
          }
        }
      }
      .tipsItem {
        padding-top: 0.4rem;
        .tipsText {
          p {
            font-size: 0.32rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 0.45rem;
            text-align: center;
          }
        }
        .qrCodeItem {
          width: 2.64rem;
          height: 2.64rem;
          // background: url(https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/qrcode-wrap_409032664297979919.png)
            // no-repeat center;
          background-size: 100%;
          margin: 0.5rem auto 0;
          display: flex;
          align-items: center;
          justify-content: center;
          .qrCodeImage {
            display: block;
            width: 2.34rem;
            height: 2.34rem;
          }
        }
        .saveItem {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 0.2rem;
          p {
            font-size: 0.28rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #0379fb;
            line-height: 0.4rem;
          }
          .handIcon {
            width: 0.35rem;
            height: 0.42rem;
            margin-left: 0.08rem;
          }
        }
      }
    }
  }
}
</style>