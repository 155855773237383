<template>
  <div class="box-wrap">
    <div class="qBox">
      <!-- one -->
      <div
        class="qItem"
        :class="{
          activeItem: curent === 0,
          hideItem: isHideIndex === 0,
          showItem: isHideIndex === -1,
        }"
      >
        <div class="qHeader oneItem">
          <img
            class="icon"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/success-icon_409031363782721576.png"
            alt=""
          />
          <h3>预约信息已提交</h3>
          <p>花15秒完成下面预约资料，</p>
          <p>我们为您更好的安排免费参观服务</p>
        </div>
        <div class="qContentCss">
          <div class="qContent">
            <template v-for="(item, index) of questionOne.list">
              <QuestionChoose
                :questionData="item"
                questionKey="question1"
                :questionIndex="index"
                type="two"
                :key="index"
                @emitQuestionClick="emitQuestionClick"
              >
                <template #title>
                  <div class="questionTitle pd64" v-if="index === 0">
                    <div class="titleIndex">
                      <h3>1</h3>
                      <h4>/4</h4>
                    </div>
                    <p>请问您是给家里哪位亲人看墓？</p>
                  </div>
                  <div class="centerTitle" v-else>
                    是否需要<span>殡仪服务</span>
                  </div>
                </template>
              </QuestionChoose>
            </template>
          </div>
        </div>
        <div class="nextBtn" @click="nextClick">下一题</div>
      </div>
      <!-- two -->
      <div
        class="qItem"
        :class="{
          activeItem: curent === 1,
          showItem: isHideIndex === 0,
          hideItem: isHideIndex === 1,
          leftItem: curent === 10,
        }"
      >
        <div class="qHeader twoItem">
          <img
            class="hedaer-icon"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/header_409031363845636118.png"
            alt=""
          />
        </div>
        <div class="qContentCss">
          <div class="qContent">
            <template v-for="(item, index) of questionTwo.list">
              <QuestionChoose
                :questionData="item"
                questionKey="question2"
                :questionIndex="index"
                type="one"
                :key="index"
                @emitQuestionClick="emitQuestionClick"
              >
                <template #title>
                  <div class="questionTitle pd64" v-if="index === 0">
                    <div class="titleIndex">
                      <h3>2</h3>
                      <h4>/4</h4>
                    </div>
                    <p>
                      九公山墓型多样，有标准墓、艺术墓、家族自选墓，价位由1.9万至80万不等；请问您本次购墓预算大概在多少？
                    </p>
                  </div>
                </template>
              </QuestionChoose>
            </template>
          </div>
        </div>
      </div>
      <!-- three -->
      <div
        class="qItem"
        :class="{
          activeItem: curent === 2,
          showItem: isHideIndex === 1,
          hideItem: isHideIndex === 2,
          leftItem: curent === 20,
        }"
      >
        <div class="qHeader twoItem">
          <img
            class="hedaer-icon"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/header_409031363845636118.png"
            alt=""
          />
        </div>
        <div class="qContentCss">
          <div class="qContent">
            <template v-for="(item, index) of questionThree.list">
              <QuestionChoose
                :questionData="item"
                questionKey="question3"
                :questionIndex="index"
                :key="index"
                @emitQuestionClick="emitQuestionClick"
              >
                <template #title>
                  <div v-if="index === 0">
                    <div class="questionTitle">
                      <div class="titleIndex">
                        <h3>3</h3>
                        <h4>/4</h4>
                      </div>
                      <p>请问您的年龄和性别分别是？</p>
                    </div>
                    <div class="lastTitle">选择您的年龄</div>
                  </div>

                  <div v-else class="lastTitle">选择您的性别</div>
                </template>
              </QuestionChoose>
            </template>
          </div>
        </div>
        <div class="nextBtn" @click="nextClick">下一题</div>
      </div>
      <!-- four -->
      <div
        class="qItem"
        :class="{
          activeItem: curent === 3,
          showItem: isHideIndex === 2,
          hideItem: isHideIndex === 3,
          leftItem: curent === 30,
        }"
      >
        <div class="qHeader twoItem">
          <img
            class="hedaer-icon"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/header_409031363845636118.png"
            alt=""
          />
        </div>
        <div class="qContentCss">
          <div class="qContent">
            <template v-for="(item, index) of questionFour.list">
              <QuestionChoose
                :questionData="item"
                questionKey="question4"
                :questionIndex="index"
                type="one"
                :key="index"
                @emitQuestionClick="emitQuestionClick"
              >
                <template #title>
                  <div class="questionTitle pd64" v-if="index === 0">
                    <div class="titleIndex">
                      <h3>4</h3>
                      <h4>/4</h4>
                    </div>
                    <p>您什么时间段方便接听电话？</p>
                  </div>
                </template>
              </QuestionChoose>
            </template>
          </div>
        </div>
      </div>
      <div class="fiexdTip" v-if="isHideIndex > -1 && isHideIndex !== curent">
        <div v-if="isHideIndex !== curent" v-html="fixedText[curent]"></div>
      </div>
    </div>
  </div>
</template>
<script>
import QuestionChoose from "@/views/tfhd/jiugongshan/components/questionChoose.vue";
import { submitFormApi, collectPointApi } from "@/api/apiList.js";
import { mapGetters } from "vuex";
import { setChannel } from "@/util/setChannel.js";
export default {
  name: "question",
  components: {
    QuestionChoose,
  },
  data() {
    return {
      curent: 0,
      isHideIndex: -1,
      questionKeyList: ["question1", "question2", "question3", "question4"],
      questionOne: {
        index: "question1",
        list: [
          {
            list: [
              {
                key: "父母",
                value: 0,
              },
              {
                key: "爷爷/奶奶",
                value: 1,
              },
              {
                key: "自己",
                value: 2,
              },
              {
                key: "其他家人",
                value: 3,
              },
            ],
          },
          {
            list: [
              {
                key: "是",
                value: 1,
              },
              {
                key: "否",
                value: 2,
              },
            ],
          },
        ],
      },
      questionTwo: {
        name: "question2",
        list: [
          {
            list: [
              {
                key: "标准墓（5万以下）",
                value: 0,
              },
              {
                key: "标准墓（5万至15万）",
                value: 1,
              },
              {
                key: "标准墓（15万至30万）",
                value: 2,
              },
              {
                key: "艺术墓（30万至60万）",
                value: 3,
              },
              {
                key: "家族自选地（60万以上）",
                value: 4,
              },
            ],
          },
        ],
      },
      questionThree: {
        index: "question3",
        list: [
          {
            list: [
              {
                key: "25岁以下",
                value: 0,
              },
              {
                key: "25-35岁",
                value: 1,
              },
              {
                key: "35-45岁",
                value: 2,
              },
              {
                key: "45-55岁",
                value: 3,
              },
              {
                key: "55-65岁",
                value: 4,
              },
              {
                key: "65岁以上",
                value: 5,
              },
            ],
          },
          {
            list: [
              {
                key: "先生",
                value: 0,
              },
              {
                key: "女士",
                value: 1,
              },
            ],
          },
        ],
      },
      questionFour: {
        name: "question4",
        list: [
          {
            list: [
              {
                key: "上午  09:00-12:00",
                value: 0,
              },
              {
                key: "中午   12:00-15:00",
                value: 1,
              },
              {
                key: "下午  15:00-18:00",
                value: 2,
              },
              {
                key: "晚上   18:00-21:00",
                value: 3,
              },
              {
                key: "以上时间段都可以",
                value: 4,
              },
            ],
          },
        ],
      },
      choseParmsDefault: {
        question1: [],
        question3: [],
      },
      choseParms: {
        question1: [],
        question3: [],
      },
      fixedText: {
        1: "<p style='font-size: 0.28rem;font-weight: 400;color: #6c340d;line-height: 0.38rem;'>再 答 <span style='  padding-left: 0.03rem;font-size: 0.38rem;color: #b91b3f;font-weight: bold;'>2</span> 题 优 先 预 约 参 观</p>",
        2: "<p style='font-size: 0.28rem;font-weight: 400;color: #6c340d;line-height: 0.38rem;'>再 答 <span style='  padding-left: 0.03rem;font-size: 0.38rem;color: #b91b3f;font-weight: bold;'>1</span> 题 优 先 预 约 参 观</p>",
        3: "<p style='font-size: 0.28rem;font-weight: 400;color: #6c340d;line-height: 0.38rem;'>完成本 题 优 先 预 约参 观</p>",
      },
      activeQuestion: [], //当前选择的答案
      activeKey: "question1", // 当前选择题
    };
  },
  created() {
    setChannel(this);
    if (!this.pages.fillPhone) {
      this.$router.push(
        `/tfhd/jiugongshan/home/${this.pages.channel}/${this.pages.subChannel}`
      );
    }
  },
  mounted() {},
  computed: {
    ...mapGetters(["pages", "UUID"]),
  },
  methods: {
    async emitQuestionClick(options) {
      console.log(options, "options");
      this.$toast.clear();
      let flag = await this.setParms(options);
      this.activeKey = options.questionKey;
      let index = options.questionIndex;
      this.activeQuestion[index] = options.data;
      if (flag) {
        this.nextClick();
      }
    },
    setParms(options) {
      let { questionKey, questionIndex, data } = options;
      return new Promise((resolve) => {
        if (!this.choseParms[questionKey]) {
          this.choseParms[questionKey] = [];
        }
        this.choseParms[questionKey][questionIndex] = data;
        switch (questionKey) {
          case "question1":
            resolve(false);
            break;
          case "question3":
            resolve(false);
            break;
          default:
            resolve(true);
            break;
        }
      });
    },
    nextClick() {
      let params = {
        phone: this.pages.fillPhone,
        channelCode: this.pages.channel,
        subChannelCode: this.pages.subChannel,
        activityId: this.pages.activityId,
        [this.activeKey]: this.activeQuestion,
      };
      console.log("执行~~~,", params);
      this.activeQuestion = [];
      submitFormApi(params)
        .then((res) => {
          console.log("提交问卷成功", res);
        })
        .catch((err) => {
          console.log(err, "tijiaoshibai ");
        });
      if (this.curent === 3) {
        this.buryingPage()
        this.$router.push(`/tfhd/jiugongshan/success?repeat=0`);
        return;
      }
      this.$toast.clear();
      let length = 0;
      switch (this.curent) {
        case 0:
          length = 2;
          break;
        case 1:
          length = 1;
          break;
        case 2:
          length = 2;
          break;
        case 3:
          length = 1;
          break;
        default:
          length = 0;
          break;
      }
      for (let i = 0; i < length; i++) {
        const item = this.choseParms[this.questionKeyList[this.curent]][i];
        if (!item) {
          return this.$toast.fail("请先完成问卷选项");
        }
      }

      let curent = this.curent;
      this.curent = 999;
      setTimeout(() => {
        this.curent = (curent + 1) * 10;
        setTimeout(() => {
          this.isHideIndex = curent;
          setTimeout(() => {
            this.curent = curent + 1;
            this.choseParms = JSON.parse(
              JSON.stringify(this.choseParmsDefault)
            );
          }, 100);
        }, 100);
      }, 100);
    },
    setActiveQuestion() {
      return new Promise((resolve) => {
        // this.activeQuestion
        let activeQuestion = this.$deepCopyFn(this.activeQuestion);
        activeQuestion = this.onlyArr(activeQuestion);
        console.log(activeQuestion, "activeQuestion");
        let newArr = [];
        for (const item of activeQuestion) {
          console.log(item, "dhjdh");
          if (item.checked) {
            let obj = {
              key: item.key,
              value: item.value,
            };
            newArr.push(obj);
          }
        }
        this.activeQuestion = newArr;
        resolve();
      });
    },
    onlyArr(arr) {
      let map = new Map();
      for (let i of arr) {
        if (!map.has(i.key)) {
          map.set(i.key, i);
        }
      }
      arr = [...map.values()];
      return arr;
    },
    // 埋点
    buryingPage() {
      this.collectPointParams = {
        channelCode: this.pages.channel,
        subChannelCode: this.pages.subChannel,
        activityId: this.pages.activityId,
        eventType: 5,
        uuid: this.UUID,
      };
      console.log(this.collectPointParams, " this.collectPointParams");
      if (this.pages.subChannel && this.pages.channel) {
        collectPointApi(this.collectPointParams)
          .then((res) => {
            console.log(res, "埋点成功");
          })
          .catch((err) => {
            console.log(err, "买点失败");
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.box-wrap {
  min-height: 100vh;
  box-sizing: border-box;
  background: #fff;
  padding-bottom: calc(env(safe-area-inset-bottom));
  .qBox {
    .qItem {
      display: none;
      opacity: 0;
      transform: translateX(-20rem);
      transition: all 0.6s linear;
      .qHeader {
        width: 100%;
        background: linear-gradient(
          180deg,
          rgba(246, 217, 193, 0.45) 0%,
          rgba(211, 167, 126, 0.45) 100%
        );
      }
      .oneItem.qHeader {
        height: 3.17rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .icon {
          width: 0.6rem;
          height: 0.6rem;
        }
        h3 {
          padding: 0.16rem 0;
          font-size: 0.32rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #6c340d;
          line-height: 0.45rem;
        }
        p {
          font-size: 0.32rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #b91b3f;
          line-height: 0.45rem;
        }
      }
      .qHeader.twoItem {
        width: 100%;
        .hedaer-icon {
          display: block;
          width: 100%;
          height: 2.2rem;
        }
      }
      .qContentCss {
        padding: 0 0.3rem;
        .qContent {
          background: #fff
            url(https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/q-bg-icon_409031363778527324.png)
            no-repeat right top;
          background-size: 2.04rem 2.04rem;
          box-shadow: 0px 2px 0.1rem 0px rgba(102, 75, 10, 0.1);
          padding: 0.48rem;
          .questionTitle {
            display: flex;
            align-items: baseline;

            .titleIndex {
              display: flex;
              align-items: baseline;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              h3 {
                font-size: 0.42rem;
              }
              h4 {
                font-size: 0.28rem;
              }
            }

            p {
              padding-left: 0.12rem;
              font-size: 0.32rem;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 0.46rem;
            }
          }
          .pd64 {
            padding-bottom: 0.64rem;
          }
          .centerTitle {
            padding: 0.8rem 0 0.64rem;
            text-align: center;
            font-size: 0.32rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 0.45rem;
            span {
              color: #b91b3f;
            }
          }
          .lastTitle {
            padding: 0.36rem 0;
            text-align: center;
            font-size: 0.32rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 0.45rem;
          }
        }
      }
    }
    .showItem {
      opacity: 0.5;
      display: block;
    }
    .leftItem {
      display: block;
      transform: translateX(20rem);
    }
    .activeItem {
      opacity: 1;
      transform: translateX(0);
    }

    .hideItem {
      display: none;
    }
    .nextBtn {
      width: 5.94rem;
      height: 0.86rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(180deg, #eb3659 0%, #b91b3f 100%);
      border-radius: 43px;
      margin: 0.8rem auto 0;
      font-size: 0.36rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .fiexdTip {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-wrap: wrap;
    background: url("https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/q-f-bg_409031363786915884.png")
      no-repeat center;
    background-size: 100%;
    width: 0.6rem;
    height: 4.64rem;
    justify-content: center;
    align-items: center;
    padding-left: 0.15rem;
    box-sizing: border-box;
    .text {
      font-size: 0.28rem;
      font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
      font-weight: 400;
      color: #6c340d;
      line-height: 0.38rem;
      span {
        padding-left: 0.03rem;
        font-size: 0.38rem;
        color: #b91b3f;
        font-weight: bold;
      }
    }
  }
}
</style>