import { getH5TokenApi } from "@/api/apiList.js"

export const certifiedPhone = function (vm) {
  return new Promise((resolve) => {
    getH5TokenApi().then(res => {
      let data = res.data.data.body
      let tokenInfo = data.tokenInfo
      let { accessToken, jwtToken } = tokenInfo
      vm.$phoneNumberServer.checkAuthAvailable({
        accessToken,
        jwtToken,
        success: function (success) {
          console.log(success, 'success');
          vm.$phoneNumberServer.getVerifyToken({
            success: function (TokenRes) {
              console.log(TokenRes, 'token')
              resolve(TokenRes)
            },
            error: function (err) {
              console.log('失败的token', err);
              return resolve(false)
            }
          });
        },
        error: function (err) {
          console.log(err, 'shibai');
          return resolve(false)

        }
      });

    })
  })
}